import { createSlice } from "@reduxjs/toolkit"

export const authSlice = createSlice({
  name: "user",
  initialState: {
    user: localStorage.getItem("user") || null,
    isLoggedIn: !!localStorage.getItem("token"),
    token: localStorage.getItem("token") || null,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user
      state.token = action.payload.token
      state.isLoggedIn = true
      localStorage.setItem("user", JSON.stringify(action.payload.user))
      localStorage.setItem("token", action.payload.token)
    },
    logout: (state) => {
      state.user = null
      state.token = null
      state.isLoggedIn = false
      localStorage.removeItem("user")
      localStorage.removeItem("token")
    },
  },
})

export const { login, logout } = authSlice.actions

export const selectUser = (state: { auth: { user: string } }) => state.auth.user
export const selectToken = (state: { auth: { token: string } }) => state.auth.token

export default authSlice.reducer
