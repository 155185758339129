import React, { useEffect, useState } from "react"
import { Brand, Meeting } from "../types/BrandData"
import "./styles/styles.css"
import {
  TAbrandSalesReadiness,
  TAgetBrandCampaigns,
  TAupdateBalance,
  TAupdateBrand,
  TAupdateBrandLogo,
  TAupdateBrandMeeting,
  TAupdateBrandNote,
} from "../services/brandAPI"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import TimePicker from "react-time-picker"
import "react-time-picker/dist/TimePicker.css"
import CampaignCard from "./CampaignCard"
import UpdateBrandBalance from "./UpdateBrandBalance"

const BrandProfile = (brand: Brand) => {
  const [brand_logo, setBrandLogo] = useState(brand.brand_logo)
  const [balance, setBalance] = useState(brand.balance)
  const [newBalance, setNewBalance] = useState(brand.balance)
  const [notes, setNotes] = useState(brand.notes)
  const [newNotes, setNewNotes] = useState("")
  const [meetings, setMeetings] = useState<Meeting[]>(brand.meetings ?? [])
  const [editBalance, setEditBalance] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [logo_url, setLogo_url] = useState("")
  const [brandNotes, setBrandNotes] = useState([""])
  const [meetingDescription, setMeetingDescription] = useState("")
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [brandCampaigns, setBrandCampaigns] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [operation, setOperation] = useState("")
  const [rate, setRate] = useState(brand.sales_readiness || 0)
  const [editor, setEditor] = useState(false)
  const [editRate, setEditRate] = useState(rate)

  useEffect(() => {
    handleCampaigns()
    if (Array.isArray(notes) && notes.length > 0) {
      setBrandNotes(notes)
    } else {
      setBrandNotes([""])
    }
  }, [notes])

  useEffect(() => {
    if (meetings && Array.isArray(meetings)) {
      setMeetings(meetings)
    }
  }, [meetings])

  const brandInfo: { key: string; value: any }[] = [
    { key: "Marka İsmi", value: brand.brand_name },
    { key: "Email", value: brand.email },
    { key: "Telefon", value: brand.phone },
    { key: "Ülke", value: brand.country },
    { key: "İsim", value: brand.first_name },
    { key: "Soyisim", value: brand.last_name },
    { key: "Para Birimi", value: brand.currency || "TRY" },
    { key: "Dil", value: brand.language || "TR" },
    { key: "Unvan", value: brand.job_title },
    { key: "Bakiye", value: balance },
    { key: "Satışa Yakınlık", value: rate },
    { key: "Marka Id", value: brand._id },
  ]

  const handleCampaigns = async () => {
    try {
      const response = await TAgetBrandCampaigns(brand._id)
      if (response) {
        setBrandCampaigns(response)
        setTotalPages(response.totalPages)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleUpdateNote = async (brandNotes: any) => {
    try {
      const response = await TAupdateBrandNote(brand._id, brandNotes)
      if (response) {
        setNotes(brandNotes)
        setNewNotes("")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteNote = (index: any) => {
    const newNotes = brandNotes.filter((note, noteIndex) => noteIndex !== index)
    handleUpdateNote(newNotes)
  }

  const handleUpdateMeeting = async (brandMeetings: any) => {
    try {
      const response = await TAupdateBrandMeeting(brand._id, brandMeetings)
      if (response) {
        setMeetings(brandMeetings)
        setMeetingDescription("")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteMeeting = (index: any) => {
    if (meetings !== undefined) {
      const newMeetings = meetings.filter((meeting, meetingIndex) => meetingIndex !== index)
      handleUpdateMeeting(newMeetings)
    }
  }

  const handleUploadPhoto = async (logo_url: any) => {
    try {
      await TAupdateBrand(brand._id, { brand_logo: logo_url })
      setIsOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectPhotoFromPC = async () => {
    try {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "image/*"
      input.onchange = async (e) => {
        if (!(e.target instanceof HTMLInputElement) || !e.target.files) {
          return
        }

        const file = e.target.files[0]
        const formData = new FormData()
        formData.append("brand_logo", file)

        try {
          const response = await TAupdateBrandLogo(brand._id, formData)
          if (response.status !== 200) {
            throw new Error("Failed to upload brand logo")
          }
          setIsOpen(false)
          setBrandLogo(response.data.brand.brand_logo)
        } catch (error) {
          console.error("Error uploading photo:", error)
        }
      }
      input.click()
    } catch (error) {
      console.error("Error selecting photo:", error)
    }
  }

  const handleDateChange = (date: any) => {
    setSelectedDate(date)
  }

  const handleTimeChange = (time: any) => {
    if (time !== null) {
      const newDate = new Date(selectedDate)
      const [hours, minutes] = time.split(":")
      newDate.setHours(hours)
      newDate.setMinutes(minutes)
      setSelectedDate(newDate)
    }
  }

  const handleChangeBalance = async (newBalance: number, operation: string) => {
    try {
      const response = await TAupdateBalance(brand._id, newBalance, operation)
      if (response) {
        setEditBalance(false)
        setBalance(newBalance)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSalesReadiness = async (_id: string, rate: number) => {
    try {
      const response = await TAbrandSalesReadiness(_id, rate)
      if (response) {
        setRate(rate)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const goToNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1)
    }
  }

  return (
    <div>
      <div className="profile-section bg-white p-3 shadow-md mb-3 w-3/5 rounded-2xl">
        <h3 className="section-title text-xl font-bold mb-3">Marka Bilgileri</h3>
        {brand_logo && (
          <div className="flex items-center">
            <img src={brand_logo} alt="Brand Logo" className="w-20 h-20 rounded-full mr-4" />
            {!isOpen && (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setIsOpen(true)}
              >
                Resmi Değiştir
              </button>
            )}
          </div>
        )}
        {!brand_logo && (
          <div
            className="w-20 h-20 rounded-full bg-gray-300 flex items-center justify-center text-gray-600 text-sm font-bold cursor-pointer"
            onClick={() => setIsOpen(true)}
          >
            Resim Ekle
          </div>
        )}
        {isOpen && (
          <div>
            <input
              type="text"
              placeholder="photo url'i girin"
              value={logo_url}
              onChange={(e) => setLogo_url(e.target.value)}
              className="border border-gray-400 rounded py-2 px-4 mb-2 mt-2"
            />
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-2 rounded"
              onClick={() => handleUploadPhoto(logo_url)}
            >
              Kaydet
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-2 rounded"
              onClick={() => handleSelectPhotoFromPC()}
            >
              Bilgisayardan Yükle
            </button>
          </div>
        )}

        <table className="table-responsive">
          <tbody>
            {brandInfo.map((info, index) => (
              <tr key={index}>
                {info.key === "Bakiye" ? (
                  <>
                    <td className="font-bold text-md">Bakiye</td>
                    <td className="font-semibold">=</td>
                    <td className="font-semibold">{info.value}</td>
                    <button
                      className="text-indigo-600 hover:text-indigo-900 pt-3"
                      onClick={() => setEditBalance(!editBalance)}
                    >
                      Bakiyeyi güncelle
                    </button>
                  </>
                ) : info.key === "Satışa Yakınlık" ? (
                  <>
                    <td className="font-bold text-md">Satışa Yakınlık</td>
                    <td className="font-semibold">=</td>
                    <td className="font-semibold">{info.value}</td>
                    <button
                      className="text-indigo-600 hover:text-indigo-900 pt-3"
                      onClick={() => setEditor(!editor)}
                    >
                      Oranı güncelle
                    </button>
                    {editor && (
                      <div className="flex flex-col space-y-2 pb-2">
                        <div className="flex flex-row space-x-1">
                          <input
                            id="phone"
                            type="tel"
                            placeholder="0-100"
                            value={editRate}
                            className="form-input text-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-60 pt-1 pb-1"
                            onChange={(e) => setEditRate(Number(e.target.value))}
                          />
                          <button
                            className="text-sm font-medium text-indigo-600 hover:text-indigo-900"
                            onClick={() => {
                              handleSalesReadiness(brand._id, editRate)
                              setEditor(!editor)
                            }}
                          >
                            Güncelle
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <td className="font-bold text-md">{info.key}</td>
                    <td className="font-semibold">=</td>
                    <td className="font-semibold">{info.value}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {/* Brand Notes */}
        <div className="flex flex-col mt-2">
          <label className="text-sm font-semibold text-gray-500">Notlar</label>
          <div className="flex bg-white p-4 rounded-lg shadow border border-gray-200">
            <div className="flex flex-col mt-2">
              <textarea
                className="w-full p-2 border border-gray-200 rounded-md mb-4"
                placeholder="Write a note"
                value={newNotes}
                onChange={(e) => setNewNotes(e.target.value)}
              />
              <button
                className="bg-blue-500 text-white rounded-md px-3 py-2 w-full"
                onClick={() => handleUpdateNote([...brandNotes, newNotes])}
              >
                Not Ekle
              </button>
            </div>
            <div className="flex-grow ml-8">
              <ul>
                {brandNotes
                  .filter((note) => note !== "")
                  .map((note, index) => (
                    <li key={index} className="flex items-center">
                      <p className="text-gray-600 mt-2">{note}</p>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="mt-2"
                        style={{ color: "#005eff", marginLeft: "8px", cursor: "pointer" }}
                        onClick={() => handleDeleteNote(index)}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        {/* Brand Meetings */}
        <div className="flex flex-col mt-2">
          <label className="text-sm font-semibold text-gray-500">Toplantılar</label>
          <div className="flex bg-white p-4 rounded-lg shadow border border-gray-200">
            <div className="flex flex-col mt-2">
              <TimePicker
                onChange={handleTimeChange}
                value={
                  selectedDate
                    ? selectedDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
                    : ""
                }
                className="w-full p-2 border border-gray-200 rounded-md mb-4"
                disableClock={true}
                clearIcon={null}
              />
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                className="w-full p-2 border border-gray-200 rounded-md mb-4"
                placeholderText="mm/dd/yyyy"
              />
              <textarea
                className="w-full p-2 border border-gray-200 rounded-md mb-4"
                placeholder="Add a description"
                value={meetingDescription}
                onChange={(e) => setMeetingDescription(e.target.value)}
              />
              <button
                className="bg-blue-500 text-white rounded-md px-3 py-2 w-full"
                onClick={() =>
                  handleUpdateMeeting([
                    ...meetings,
                    { time: selectedDate, description: meetingDescription },
                  ])
                }
              >
                Toplantı Ekle
              </button>
            </div>
            <div className="flex-grow ml-8">
              <ul>
                {meetings.map((meeting, index) => (
                  <li key={index} className="flex items-center">
                    <p className="text-gray-600 mt-2">
                      {new Date(meeting.time).toLocaleString("tr-TR")}
                    </p>
                    <p className="text-gray-600 mt-2 ml-4 font-bold">{meeting.description}</p>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="mt-2"
                      style={{ color: "#005eff", marginLeft: "8px", cursor: "pointer" }}
                      onClick={() => handleDeleteMeeting(index)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {brand?.billing_address && Object.values(brand.billing_address).some((value) => value) && (
          <div className="mt-5">
            <h3 className="section-title text-xl font-semibold mb-3">Ödeme Bilgileri</h3>
            <table className="flex">
              <tbody>
                <tr className="flex w-full flex-col ml-3 info-value">
                  {Object.entries(brand.billing_address).map(([key, value]) => (
                    <td key={key} className="font-bold">
                      {key.charAt(0).toUpperCase() + key.slice(1)}:{" "}
                      <span className="font-semibold">{value}</span>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div>
        {brandCampaigns.length > 0 ? (
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-gray-800 mt-10">
              Markaya Ait Kampanyalar
            </h3>
            <div className="overflow-x-auto py-4">
              <div className="flex space-x-4">
                {brandCampaigns.map((campaign, index) => (
                  <CampaignCard key={index} campaign={campaign} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center text-gray-600">Markaya Ait Kampanya Bulunmamaktadır</p>
        )}
      </div>
      {brand?.money_exchanges && brand?.money_exchanges?.length > 0 && (
        <div>
          <h3 className="section-title text-xl font-semibold mt-10">İşlem Geçmişi</h3>
          <table className="table-auto mt-4">
            <thead>
              <tr>
                <th className="px-4 py-2 text-xs text-gray-500">Operasyon</th>
                <th className="px-4 py-2 text-xs text-gray-500">Miktar</th>
                <th className="px-4 py-2 text-xs text-gray-500">Başvuru ID</th>
                <th className="px-4 py-2 text-xs text-gray-500">İşlem Tarihi</th>
              </tr>
            </thead>
            <tbody>
              {brand.money_exchanges?.map((exchange, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                  <td className="px-4 py-2 text-sm">{exchange.operation}</td>
                  <td className="px-4 py-2 text-sm">{exchange.amount}</td>
                  <td className="px-4 py-2 text-sm">{exchange.application_id}</td>
                  <td className="px-4 py-2 text-sm">
                    {new Date(exchange.action_time).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <UpdateBrandBalance
        modalOpen={editBalance}
        onClose={() => setEditBalance(false)}
        newBalance={newBalance}
        setNewBalance={setNewBalance}
        operation={operation}
        setOperation={setOperation}
        handleChangeBalance={handleChangeBalance}
      />
    </div>
  )
}

export default BrandProfile
