import apiClient from "./axiosInstance"

export const TAfindApplicationByCampaignId = async (campaign_id: any, state: string) => {
  const response = await apiClient.get(
    `/admin/application/campaign_id/${campaign_id}?state=${state}`,
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TACreateApplication = async (
  applicationUser: {
    tiktok: any
    user_id: any
    insta_username: any
    age: any
    gender: any
    verification: any
    state: any
    school_type?: any
    school_name?: any
    city?: any
    country?: any
    language?: any
    currency?: any
    job?: any
    birthday?: any
    insta: any
    price_user: any
    price_brand: any
    tiktok_username: any
  },
  campaign_id: string,

  selectedState: string,
  useCredentials: any,
  campaign_type: string,
) => {
  const response = await apiClient.post(
    "/admin/application/create",
    {
      user: applicationUser,
      campaign_id: campaign_id,
      state: selectedState,
      use_credentials: useCredentials,
      campaign_type: campaign_type,
    },
    {
      timeout: 120000,
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Creating application failed")
  }
}

export const TAupdateApplication = async (update: any, _id: string) => {
  const response = await apiClient.post(
    "/admin/application/update",
    {
      _id: _id,
      update: update,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Updating application pricing failed")
  }
}

export const TAuploadUserPayload = async (id: any, data: any, type: string) => {
  const response = await apiClient.post(`/admin/application/upload/${id}?type=${type}`, data, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent></calculated>",
    },
  })
  return response
}

export const TAassignUserToApplication = async (id: any, data: any) => {
  const response = await apiClient.post(
    `/admin/application/assign-user/${id}`,
    { user_id: data },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  return response
}

export const TAfindApplications = async (query: any) => {
  const response = await apiClient.get(`/admin/application/user_applications?${query}`, {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAgetAllApplications = async (
  page: number,
  perPage: number,
  params: any,

  sortBy: string,
  sortOrder: boolean,
) => {
  const response = await apiClient.get(
    `/admin/application/all_applications?${params}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAgetApplications = async (
  state: string,
  page: number,
  perPage: number,
  sortBy?: string,
  sortOrder?: boolean,
) => {
  const response = await apiClient.get(
    `/admin/application/applications?state=${state}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAapplicationIsCalled = async (
  id: string,
  is_called: boolean,
  is_called_by: string,
) => {
  const response = await apiClient.post(`/admin/application/is-called/${id}`, {
    is_called,
    is_called_by,
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAapplicationInfoState = async (id: string, info_state: string) => {
  const response = await apiClient.post(`/admin/application/info-state/${id}`, {
    info_state,
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}
