import React from "react"

export const generateAvailableStates = (
  state: string,
  barter: boolean,
  setAvailableStates: any,
) => {
  switch (state) {
    case "invite_new":
      setAvailableStates(["invite_success", "brand_canceled", "user_canceled"])
      break
    case "invite_success":
      if (barter === true) {
        setAvailableStates(["waiting_address", "brand_canceled", "user_canceled"])
      } else if (barter === false) {
        setAvailableStates(["waiting_content", "brand_canceled", "user_canceled"])
      }
      break
    case "ugc_content":
      setAvailableStates(["application_done", "ugc_rejected", "brand_canceled", "user_canceled"])
      break
    case "ugc_rejected":
      setAvailableStates(["ugc_content", "brand_canceled", "user_canceled"])
      break
    case "first_application":
      if (barter === true) {
        setAvailableStates([
          "waiting_address",
          "account_rejected",
          "brand_canceled",
          "user_canceled",
        ])
      } else if (barter === false) {
        setAvailableStates([
          "waiting_content",
          "account_rejected",
          "brand_canceled",
          "user_canceled",
        ])
      }
      break
    case "waiting_address":
      setAvailableStates(["address_to_approve", "brand_canceled", "user_canceled"])
      break
    case "address_to_approve":
      setAvailableStates([
        "waiting_address",
        "address_rejected",
        "waiting_content",
        "brand_canceled",
        "user_canceled",
      ])
      break
    case "address_rejected":
      setAvailableStates([
        "waiting_address",
        "address_to_approve",
        "brand_canceled",
        "user_canceled",
      ])
      break
    case "waiting_content":
      setAvailableStates(["content_to_share", "brand_canceled", "user_canceled", "waiting_address"])
      break
    case "content_to_share":
      setAvailableStates([
        "content_rejected",
        "application_done",
        "content_approved",
        "brand_canceled",
        "user_canceled",
      ])
      break
    case "content_approved":
      setAvailableStates(["application_done", "brand_canceled", "user_canceled"])
      break
    case "content_shared":
      setAvailableStates(["application_done", "brand_canceled", "user_canceled"])
      break
    case "content_rejected":
      setAvailableStates(["content_to_share", "brand_canceled", "user_canceled"])
      break
    case "brand_canceled":
      setAvailableStates([
        "first_application",
        "account_rejected",
        "waiting_address",
        "waiting_content",
        "address_to_approve",
        "address_rejected",
        "content_to_share",
        "content_rejected",
        "content_approved",
        "application_done",
        "user_canceled",
      ])
      break
    case "user_canceled":
      setAvailableStates([
        "first_application",
        "account_rejected",
        "waiting_address",
        "waiting_content",
        "address_to_approve",
        "address_rejected",
        "content_to_share",
        "content_rejected",
        "content_approved",
        "application_done",
        "user_canceled",
      ])
      break
    default:
      setAvailableStates([""])
  }
}

export const generateStatePayloads = (state: string, setStatePayloadTypes: any) => {
  switch (state) {
    case "ugc_rejected":
      setStatePayloadTypes(["review"])
      break
    case "ugc_content":
      setStatePayloadTypes(["img"])
      break
    case "content_to_share":
      setStatePayloadTypes(["img"])
      break
    case "content_rejected":
      setStatePayloadTypes(["review"])
      break
    default:
      setStatePayloadTypes([""])
  }
}

export const handleStatePayload = (
  selectedStatePayloads: any,
  newState: any,
  newStatePayload: any,
  user_id: any,
  setUser_id: any,
  setNewStatePayload: any,
  handleSelectPhotoFromPC: any,
  setSelectedSocialMedia: any,
  selectedSocialMedia: any,
  view: any,
  setView: any,
  reelsLikes: any,
  setReelsLikes: any,
  reelsComments: any,
  setReelsComments: any,
  reelsInteractions: any,
  setReelsInteractions: any,
  reelsSavings: any,
  setReelsSavings: any,
  reelsSharings: any,
  setReelsSharings: any,
  address: any,
  setAddress: any,
) => {
  switch (selectedStatePayloads) {
    case "link":
      return (
        <div>
          <span className="text-gray-700">Kullanıcı ID</span>
          <input
            type="string"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={user_id}
            onChange={(e) => {
              setUser_id(e.target.value)
            }}
          />
          <span className="text-gray-700">Medya Url</span>
          <input
            type="url"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={newStatePayload}
            onChange={(e) => {
              setNewStatePayload(e.target.value)
            }}
          />
        </div>
      )
    case "review":
      return (
        <div>
          <span className="text-gray-700">Gözden Geçir</span>
          <input
            type="string"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={newStatePayload}
            onChange={(e) => {
              setNewStatePayload(e.target.value)
            }}
          />
        </div>
      )
    case "img":
      return (
        <div>
          <div>
            <span className="text-gray-700">Resim/Video Url</span>
            <div className="flex mb-2">
              <input
                type="string"
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                value={newStatePayload}
                onChange={(e) => {
                  setNewStatePayload(e.target.value)
                }}
              />
              <button
                type="button"
                className="bg-blue-500 text-white hover:bg-blue-600 rounded ml-2"
                onClick={() => handleSelectPhotoFromPC("image")}
              >
                Resim Seç
              </button>
              <button
                type="button"
                className="bg-blue-500 text-white hover:bg-blue-600 rounded ml-2"
                onClick={() => handleSelectPhotoFromPC("video")}
              >
                Video Seç
              </button>
            </div>
            {newState === "application_done" && (
              <div>
                <div>
                  <label className="block">
                    <span className="text-gray-700">Sosyal Medya Türü Seç</span>
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => setSelectedSocialMedia(e.target.value)}
                    >
                      <option value="">Sosyal Medya Türü Seç</option>
                      <option value={"instagram"}>Instagram</option>
                      <option value={"tiktok"}>TikTok</option>
                    </select>
                  </label>
                </div>
                {selectedSocialMedia === "tiktok" && (
                  <div>
                    <span className="text-gray-700">
                      Resim İzlenme Sayısı <span className="text-xl text-red-400">*</span>
                    </span>
                    <input
                      type="string"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={view}
                      onChange={(e) => {
                        setView(e.target.value)
                      }}
                    />
                  </div>
                )}
                {selectedSocialMedia === "instagram" && (
                  <div>
                    <span className="text-gray-700">
                      Resim İzlenme Sayısı <span className="text-xl text-red-400">*</span>
                    </span>
                    <input
                      type="string"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={view}
                      onChange={(e) => {
                        setView(e.target.value)
                      }}
                    />
                    <span className="text-gray-700">
                      Resim Reels beğenileri <span className="text-xl text-red-400">*</span>
                    </span>
                    <input
                      type="string"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={reelsLikes}
                      onChange={(e) => {
                        setReelsLikes(e.target.value)
                      }}
                    />
                    <span className="text-gray-700">
                      Resim Reels Yorumları <span className="text-xl text-red-400">*</span>
                    </span>
                    <input
                      type="string"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={reelsComments}
                      onChange={(e) => {
                        setReelsComments(e.target.value)
                      }}
                    />
                    <span className="text-gray-700">
                      Resim Reels Etkileşimleri <span className="text-xl text-red-400">*</span>
                    </span>
                    <input
                      type="string"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={reelsInteractions}
                      onChange={(e) => {
                        setReelsInteractions(e.target.value)
                      }}
                    />
                    <span className="text-gray-700">
                      Resim Reels Kaydetmeleri <span className="text-xl text-red-400">*</span>
                    </span>
                    <input
                      type="string"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={reelsSavings}
                      onChange={(e) => {
                        setReelsSavings(e.target.value)
                      }}
                    />
                    <span className="text-gray-700">
                      Resim Reels Paylaşımları <span className="text-xl text-red-400">*</span>
                    </span>
                    <input
                      type="string"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={reelsSharings}
                      onChange={(e) => {
                        setReelsSharings(e.target.value)
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )
    case "address":
      return (
        <div>
          <span className="text-gray-700">
            Contact Name <span className="text-xxs text-red-500">*required</span>
          </span>
          <input
            type="string"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="John Doe"
            value={address.contactName}
            onChange={(e) => {
              setAddress({ ...address, contactName: e.target.value })
            }}
          />
          <span className="text-gray-700">Contact Phone</span>
          <input
            type="string"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="905554441212"
            value={address.contactPhone}
            onChange={(e) => {
              setAddress({ ...address, contactPhone: e.target.value })
            }}
          />
          <span className="text-gray-700">
            City <span className="text-xxs text-red-500">*required</span>
          </span>
          <input
            type="string"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={address.city}
            placeholder="Istanbul"
            onChange={(e) => {
              setAddress({ ...address, city: e.target.value })
            }}
          />
          <span className="text-gray-700">
            Country <span className="text-xxs text-red-500">*required</span>
          </span>
          <input
            type="string"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Turkey"
            value={address.country}
            onChange={(e) => {
              setAddress({ ...address, country: e.target.value })
            }}
          />
          <span className="text-gray-700">
            Address <span className="text-xxs text-red-500">*required</span>
          </span>
          <input
            type="string"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={address.address}
            onChange={(e) => {
              setAddress({ ...address, address: e.target.value })
            }}
          />
          <span className="text-gray-700">Details</span>
          <input
            type="string"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Near the park"
            value={address.details}
            onChange={(e) => {
              setAddress({ ...address, details: e.target.value })
            }}
          />
          <span className="text-gray-700">Zip Code</span>
          <input
            type="string"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="34000"
            value={address.zipCode}
            onChange={(e) => {
              setAddress({ ...address, zipCode: e.target.value })
            }}
          />
          <span className="text-gray-700">Extra Information</span>
          <input
            type="string"
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={address.extra_information}
            onChange={(e) => {
              setAddress({ ...address, extra_information: e.target.value })
            }}
          />
        </div>
      )
  }
}
