import React, { useCallback, useEffect, useRef, useState } from "react"
import moment from "moment"
import { BiLogoInstagramAlt, BiLogoTiktok } from "react-icons/bi"
import { IoStatsChart } from "react-icons/io5"
import { MdOutlinePreview } from "react-icons/md"
import ModalField from "../../../components/ModalField"
import { TAgetApplications, TAupdateApplication } from "../../../services/applicationAPI"
import { IoMdCheckmark } from "react-icons/io"

const ContentShared = () => {
  const [isLoading, setIsloading] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(1)
  const [applications, setApplications] = useState<any[]>([])
  const [selectedItemPlatform, setSelectedItemPLatform] = useState("insta-story")
  const [imageData, setImageData] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalType, setModalType] = useState<string>("")

  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    getApplications()
  }, [page])

  const scrollPosition = useRef(0)

  useEffect(() => {
    window.scrollTo(0, scrollPosition.current)
  }, [applications])

  const getApplications = async () => {
    setIsloading(true)
    try {
      scrollPosition.current = window.scrollY
      const response = await TAgetApplications("content_shared", page, perPage)
      if (response) {
        setApplications((prev) => [...prev, ...response.applications])
        setTotalItems(response.totalDocuments)
        setIsloading(false)
      }
    } catch (error) {
      console.log("error: ", error)
    }
  }

  const lastElementRef = useCallback(
    (node: any) => {
      if (isLoading) {
        return
      }
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && applications.length < totalItems) {
          setPage((prev) => prev + 1)
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [isLoading, applications, totalItems],
  )

  const approveToContent = async (app: any) => {
    const new_state = "application_done"
    let update = {
      state: new_state,
      state_payload: "",
      state_history: {
        state: app.state,
        state_payload: app.state_payload,
      },
    }
    try {
      const response = await TAupdateApplication(update, app._id)
      if (response) {
        setApplications(applications.filter((item) => item._id !== app._id))
      }
    } catch (err) {
      console.log("🚀 ~ approveToContent ~ err:", err)
    }
  }

  const onClose = () => {
    setModalOpen(false)
  }

  return (
    <div className="mt-10">
      <div className="flex flex-row gap-x-12">
        <div>
          <p className="font-poppins-regular text-sm text-[#444449]">Paylaşılan İçerik</p>
          <p className="font-bold text-lg text-black">{totalItems || 0}</p>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-6 mt-6">
        {applications.map((item: any, index: number) => {
          let parsedPayload
          try {
            parsedPayload = JSON.parse(item?.state_payload)
            if (!parsedPayload || typeof parsedPayload !== "object") {
              throw new Error("Parsed payload is not a valid object")
            }
          } catch (error) {
            console.error("Error parsing state_payload for item:", item, error)
            parsedPayload = {}
          }
          return (
            <div
              className="w-full h-72 flex flex-row items-center justify-start border border-[#E3E3E4] rounded-xl overflow-hidden"
              key={index}
              ref={index === applications.length - 1 ? lastElementRef : null}
            >
              {parsedPayload?.img?.includes("jpg") ||
              parsedPayload?.img?.includes("jpeg") ||
              parsedPayload?.img?.includes("png") ||
              parsedPayload?.img?.includes("webp") ||
              !parsedPayload?.img?.includes("mp4") ? (
                <div className="w-full relative group">
                  <img
                    alt="insta_payload_img"
                    src={parsedPayload?.img}
                    className="w-full h-72 object-contain shrink-0 transition-opacity duration-300 group-hover:opacity-100"
                  />

                  <div className="w-16 h-8 border-[#F1F1F2]  cursor-pointer bg-[#F1F1F2] rounded-md absolute bottom-5 right-5 flex items-center justify-center z-40">
                    {item.platform?.includes("insta") ? (
                      <BiLogoInstagramAlt
                        onClick={() =>
                          window.open(`https://www.instagram.com/${item?.insta_username}`)
                        }
                        size={18}
                        className="w-1/2"
                      />
                    ) : (
                      <BiLogoTiktok
                        onClick={() =>
                          window.open(`https://www.tiktok.com/@${item?.tiktok_username}`)
                        }
                        size={18}
                        className="w-1/2"
                      />
                    )}
                    <IoStatsChart
                      onClick={() => {
                        setImageData(item)
                        setSelectedItemPLatform(item.platform)
                        setModalOpen(true)
                        setModalType("stats")
                      }}
                      size={16}
                      className="w-1/2"
                    />
                  </div>

                  <div className="opacity-0  z-20 group-hover:opacity-100  absolute inset-0 flex items-center justify-center bg-gray-400 bg-opacity-50 font-semibold">
                    <button
                      onClick={() => {
                        setImageData(item)
                        setSelectedItemPLatform(item.platform)
                        setModalOpen(true)
                      }}
                      className="flex items-center justify-center gap-2 p-2 rounded-2xl backdrop-blur-2xl text-white "
                    >
                      <MdOutlinePreview size={18} /> Önizleme
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-full relative group">
                  <video
                    src={parsedPayload?.img}
                    muted
                    loop
                    className="w-[80%] h-72 object-contain shrink-0"
                    controls={true}
                  />
                  <div className="w-16 h-8 border-[#F1F1F2]  cursor-pointer bg-[#F1F1F2] rounded-md absolute bottom-5 right-5 flex items-center justify-center z-40">
                    {item.platform?.includes("insta") ? (
                      <BiLogoInstagramAlt
                        onClick={() =>
                          window.open(`https://www.instagram.com/${item?.insta_username}`)
                        }
                        size={18}
                        className="w-1/2"
                      />
                    ) : (
                      <BiLogoTiktok
                        onClick={() =>
                          window.open(`https://www.tiktok.com/@${item?.tiktok_username}`)
                        }
                        size={18}
                        className="w-1/2"
                      />
                    )}
                    <IoStatsChart
                      onClick={() => {
                        setImageData(item)
                        setSelectedItemPLatform(item.platform)
                        setModalOpen(true)
                        setModalType("stats")
                      }}
                      size={16}
                      className="w-1/2"
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col w-full h-72 pt-3 justify-between item-start">
                <div className="flex flex-col pl-1">
                  <div className="flex flex-row gap-x-2 px-2">
                    <img
                      alt="profile_picture"
                      className="h-10 w-10 shrink-0 object-contain rounded-md"
                      src={
                        item.platform?.includes("insta")
                          ? item?.insta_profile_pic
                          : item?.tiktok_profile_pic
                      }
                    />
                    <div className="flex flex-col gap-x-1 items-start">
                      <p className="font-semibold text-base text-black">
                        @
                        {item.platform?.includes("insta")
                          ? item?.insta_username
                          : item?.tiktok_username}
                      </p>
                      <p className="font-semibold text-xs text-[#5A5A62]">
                        {" "}
                        {item.platform?.includes("insta")
                          ? item?.insta_full_name
                          : item?.tiktok_nickname}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row gap-x-2 my-2 p-2">
                    <div>
                      <p className="font-poppins-regular text-xs text-[#71717A]">Takipçi Sayısı</p>
                      <p className="font-semibold text-sm text-black">
                        {item.platform?.includes("insta")
                          ? item?.insta_followers?.toLocaleString("tr-TR")
                          : item?.tiktok_followers?.toLocaleString("tr-TR")}
                      </p>
                    </div>
                    <div>
                      <p className="font-poppins-regular text-xs text-[#71717A]">Etkileşim Oranı</p>
                      <p className="font-semibold text-sm text-black">
                        {item.platform?.includes("insta")
                          ? item?.insta_engagement_rate?.toFixed(1)
                          : item?.tiktok_engagement_rate?.toFixed(1)}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row gap-x-2 items-center justify-between px-2 py-0.5">
                    <p className="font-poppins-regular text-xs text-[#71717A]">Son Aktif Tarihi</p>
                    <p className="font-semibold text-sm text-black">
                      {moment(item?.last_action_at).format("DD.MM.YYYY")}
                    </p>
                  </div>
                  <div className="flex flex-row gap-x-2 items-center justify-between px-2 py-0.5">
                    <p className="font-poppins-regular text-xs text-[#71717A]">İzlenme Sayısı</p>
                    <p className="font-semibold text-sm text-black">
                      {item.platform?.includes("insta")
                        ? parsedPayload?.view
                        : parsedPayload?.play_count}
                    </p>
                  </div>
                  <div className="flex flex-row gap-x-2 items-center justify-between px-2  py-0.5">
                    <p className="font-poppins-regular text-xs text-[#71717A]">Tutar</p>
                    <p className="font-semibold text-sm text-black">
                      {item?.price_brand} {item?.currency}
                    </p>
                  </div>
                  <div className="flex flex-row gap-x-2 items-start justify-between px-2  py-0.5">
                    <p className="font-poppins-regular text-xs text-[#71717A] mt-0.5">Kampanya</p>
                    <p className="font-semibold text-sm text-black">
                      {item?.name?.length > 50 ? item.name.slice(0, 35) + "..." : item?.name}
                    </p>
                  </div>
                  <div className="flex flex-row gap-x-2 items-start justify-between px-2  py-0.5">
                    <p className="font-poppins-regular text-xs text-[#71717A] mt-0.5">Platform</p>
                    <p className="font-semibold text-sm text-black">{item?.platform || ""}</p>
                  </div>
                </div>
                <div className="flex flex-row">
                  <button
                    onClick={() => approveToContent(item)}
                    className="w-full font-bold text-black h-12 bg-[#BBF7D0] flex items-center justify-center gap-2"
                  >
                    <IoMdCheckmark size={20} />
                    Onayla
                  </button>
                </div>
              </div>
            </div>
          )
        })}
        {imageData && (
          <ModalField
            modalOpen={modalOpen}
            onClose={onClose}
            data={imageData}
            platform={selectedItemPlatform}
            modalType={modalType}
          />
        )}
      </div>
      {isLoading && (
        <div className="flex items-center justify-center h-40">
          <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
        </div>
      )}
    </div>
  )
}

export default ContentShared
