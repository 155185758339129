import React, { useEffect, useState } from "react"
import { TAchangePayment, TAuserPayments } from "../../../services/statisticsAPI"
import { DataTable } from "mantine-datatable"
import DatePick from "./components/DatePicker"
import Selecter from "./components/Selecter"

const UserPayments = () => {
  const [payments, setPayments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [query, setQuery] = useState({
    dateStart: "",
    dateEnd: "",
    targetDateStart: "",
    targetDateEnd: "",
    paymentDateStart: "",
    paymentDateEnd: "",
    lastPaymentTryStart: "",
    lastPaymentTryEnd: "",
    status: "",
  })

  const options = ["", "PENDING", "SUCCESS", "DENIED", "FAILED", "REQUESTED"]

  useEffect(() => {
    getPayments()
  }, [page, perPage, query])

  const getPayments = async () => {
    setIsLoading(true)
    try {
      const response = await TAuserPayments(query, page, perPage)
      if (response) {
        setPayments(response.payments)
        setTotal(response.totalDocuments)
      }
      setIsLoading(false)
    } catch (error) {
      console.log("error", error)
      setError(true)
      setIsLoading(false)
    }
  }

  const changePaymentState = async (payment_id: string, state: string) => {
    try {
      const response = await TAchangePayment(payment_id, state)
      if (response) {
        setPayments((prev: any) =>
          prev.map((item: any) => {
            if (item._id === payment_id) {
              return { ...item, status: state }
            }
            return item
          }),
        )
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <div className="panel">
      <div className="flex flex-col mt-2">
        {error && <div className="text-red-500">Bir hata oluştu</div>}
        <div className="flex gap-2 z-10">
          <DatePick
            title="Tarih Aralığı"
            selected1={query.dateStart ? new Date(query.dateStart) : null}
            selected2={query.dateEnd ? new Date(query.dateEnd) : null}
            onChange1={(date) =>
              setQuery({
                ...query,
                dateStart: date ? date.toISOString().split("T")[0] : "",
              })
            }
            onChange2={(date) =>
              setQuery({
                ...query,
                dateEnd: date ? date.toISOString().split("T")[0] : "",
              })
            }
            placeholderText1="Başlangıç Tarihi"
            placeholderText2="Bitiş Tarihi"
          />
          <DatePick
            title="Hedeflenen Tarih"
            selected1={query.targetDateStart ? new Date(query.targetDateStart) : null}
            selected2={query.targetDateEnd ? new Date(query.targetDateEnd) : null}
            onChange1={(date) =>
              setQuery({
                ...query,
                targetDateStart: date ? date.toISOString().split("T")[0] : "",
              })
            }
            onChange2={(date) =>
              setQuery({
                ...query,
                targetDateEnd: date ? date.toISOString().split("T")[0] : "",
              })
            }
            placeholderText1="Başlangıç Tarihi"
            placeholderText2="Bitiş Tarihi"
          />
          <DatePick
            title="Ödeme Tarihi"
            selected1={query.paymentDateStart ? new Date(query.paymentDateStart) : null}
            selected2={query.paymentDateEnd ? new Date(query.paymentDateEnd) : null}
            onChange1={(date) =>
              setQuery({
                ...query,
                paymentDateStart: date ? date.toISOString().split("T")[0] : "",
              })
            }
            onChange2={(date) =>
              setQuery({
                ...query,
                paymentDateEnd: date ? date.toISOString().split("T")[0] : "",
              })
            }
            placeholderText1="Başlangıç Tarihi"
            placeholderText2="Bitiş Tarihi"
          />
          <DatePick
            title="Son Ödeme Denemesi"
            selected1={query.paymentDateStart ? new Date(query.paymentDateStart) : null}
            selected2={query.paymentDateEnd ? new Date(query.paymentDateEnd) : null}
            onChange1={(date) =>
              setQuery({
                ...query,
                paymentDateStart: date ? date.toISOString().split("T")[0] : "",
              })
            }
            onChange2={(date) =>
              setQuery({
                ...query,
                paymentDateEnd: date ? date.toISOString().split("T")[0] : "",
              })
            }
            placeholderText1="Başlangıç Tarihi"
            placeholderText2="Bitiş Tarihi"
          />
          <div className="mt-4">
            <Selecter
              options={options}
              disabled_option="Durum Seçiniz"
              setSelected={(value) => setQuery({ ...query, status: value })}
            />
          </div>
        </div>
      </div>
      <div className="datatables">
        {isLoading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={payments}
            columns={[
              {
                accessor: "",
                title: "Profil İsmi",
                sortable: true,
                render: (row: any) =>
                  row.user?.name || row.user?.surname
                    ? `${row.user?.name ?? ""} ${row.user?.surname ?? ""}`.trim()
                    : "",
              },
              { accessor: "account_type", title: "Hesap Tipi", sortable: true },
              { accessor: "account_id", title: "Hesap ID", sortable: true },
              { accessor: "mount", title: "Miktar", sortable: true },
              { accessor: "currency", title: "Para Birimi", sortable: true },
              {
                accessor: "date",
                title: "Tarih (UTC+3)",
                sortable: true,
                render: (row) => (row.date ? new Date(row.date).toLocaleString("tr-TR") : "-"),
              },
              {
                accessor: "target_date",
                title: "Hedeflenen Tarih (UTC+3)",
                sortable: true,
                render: (row) =>
                  row.target_date ? new Date(row.target_date).toLocaleString("tr-TR") : "-",
              },
              {
                accessor: "payment_date",
                title: "Ödeme Tarihi (UTC+3)",
                sortable: true,
                render: (row) =>
                  row.payment_date ? new Date(row.payment_date).toLocaleString("tr-TR") : "-",
              },
              {
                accessor: "last_payment_try",
                title: "Son Ödeme Tarihi (UTC+3)",
                sortable: true,
                render: (row) =>
                  row.last_payment_try
                    ? new Date(row.last_payment_try).toLocaleString("tr-TR")
                    : "-",
              },
              { accessor: "status", title: "Durum", sortable: true },
              {
                accessor: "",
                title: "İşlem",
                sortable: true,
                render: (row) => (
                  <div className="flex gap-2">
                    {row.status === "REQUESTED" && (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => changePaymentState(row._id, "PENDING")}
                      >
                        Onayla
                      </button>
                    )}
                  </div>
                ),
              },
            ]}
            totalRecords={total}
            recordsPerPage={perPage}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPerPage}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default UserPayments
