import React, { useEffect, useState } from "react"
import { Campaign } from "../types/CampaignData"
import "./styles/styles.css"
import {
  TAupdateCampaignNotes,
  TAupdateCampaign,
  TAupdateCampaignLogo,
  TAcampaignLimitationApplications,
  TAsendVoiceMessage,
} from "../services/campaignsAPI"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import UserCard from "./UserCard"
import AddApplicationModal from "./AddApplicationModal"
import { ShieldExclamationIcon } from "@heroicons/react/20/solid"

export const CampaignProfile = (data: Campaign) => {
  const [coverPhoto, setCoverPhoto] = useState(data.cover_photo)
  const [notes, setNotes] = useState(data.notes || [])
  const [newNotes, setNewNotes] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [logo_url, setLogo_url] = useState("")
  const [isBarterDropdownOpen, setIsBarterDropdownOpen] = useState(false)
  const [barter, setBarter] = useState(data.barter)
  const [barterDetails, setBarterDetails] = useState(data.barter_details)
  const [barterDescription, setBarterDescription] = useState(data.barter_description)
  const [newBarter, setNewBarter] = useState(data.barter || false)
  const [newBarterDetails, setNewBarterDetails] = useState(data.barter_details || "")
  const [newBarterDescription, setNewBarterDescription] = useState(data.barter_description || "")
  const [suggestedUsers, setSuggestedUsers] = useState([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false)
  const [voiceStatus, setVoiceStatus] = useState("")
  const [voiceMessageState, setVoiceMessageState] = useState("waiting_content")
  const [voiceMessageType, setVoiceMessageType] = useState("all")

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const goToNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1)
    }
  }

  const handleCampaignLimitationApplications = async (
    id: string,
    page: number,
    perPage: number,
  ) => {
    try {
      const response = await TAcampaignLimitationApplications(id, page, perPage)
      if (response) {
        setSuggestedUsers(response.data.users)
        setTotalPages(response.data.totalPages)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    handleCampaignLimitationApplications(data._id, page, perPage)
  }, [data, page, perPage])

  const handleUpdateNote = async (campaignNotes: any) => {
    try {
      const campaign: any = await TAupdateCampaignNotes(data._id, campaignNotes)
      if (campaign) {
        setNotes(campaignNotes)
        setNewNotes("")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSendVoiceMessage = async () => {
    try {
      setVoiceStatus("sending")
      const response: any = await TAsendVoiceMessage(data._id, voiceMessageState, voiceMessageType)
      if (response) {
        setVoiceStatus(
          `${voiceMessageState} aşamasındaki başvurulardan ${response.total} kullanıcıdan ${response.successfulCount} kişiye başarılı bir şekilde mesaj gönderildi.`,
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteNote = (index: any) => {
    const newNotes = notes.filter((note, noteIndex) => noteIndex !== index)
    handleUpdateNote(newNotes)
  }

  const handleUploadPhoto = async (logo_url: any) => {
    try {
      const response = await TAupdateCampaign(data._id, { cover_photo: logo_url })
      if (response) {
        setCoverPhoto(response.campaign.cover_photo)
      }
      setIsOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectPhotoFromPC = async () => {
    try {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "image/*"
      input.onchange = async (e) => {
        if (!(e.target instanceof HTMLInputElement) || !e.target.files) {
          return
        }

        const file = e.target.files[0]
        const formData = new FormData()
        formData.append("cover_photo", file)

        try {
          const response = await TAupdateCampaignLogo(data._id, formData)
          if (response.status !== 200) {
            throw new Error("Failed to upload cover photo")
          }

          if (response) {
            setCoverPhoto(response.data.campaign.cover_photo)
            setIsOpen(false)
          }
        } catch (error) {
          console.error("Error uploading photo:", error)
        }
      }
      input.click()
    } catch (error) {
      console.error("Error selecting photo:", error)
    }
  }

  const handleUpdateBarter = async (_id: string) => {
    try {
      const res = await TAupdateCampaign(_id, {
        barter: newBarter,
        barter_details: newBarterDetails,
        barter_description: newBarterDescription,
      })
      if (res) {
        setBarter(newBarter)
        setBarterDetails(newBarterDetails)
        setBarterDescription(newBarterDescription)
      }
      setIsBarterDropdownOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div className="p-6 w-full rounded-2xl">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="bg-white p-4 rounded-lg shadow border border-gray-200">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Kampanya Bilgileri</h3>
            {coverPhoto && (
              <div className="flex items-center">
                <img src={coverPhoto} alt="Brand Logo" className="w-28 h-28 rounded-full mr-4" />
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => setIsOpen(true)}
                >
                  Resmi Değiştir
                </button>
              </div>
            )}
            {!coverPhoto && (
              <div
                className="w-20 h-20 rounded-full bg-gray-300 flex items-center justify-center text-gray-600 text-sm font-bold cursor-pointer"
                onClick={() => setIsOpen(true)}
              >
                Resim Ekle
              </div>
            )}
            {isOpen && (
              <div className="mt-2">
                <input
                  type="text"
                  placeholder="Enter photo url"
                  value={logo_url}
                  onChange={(e) => setLogo_url(e.target.value)}
                  className="border border-gray-400 rounded py-2 px-4 mb-2"
                />
                <button
                  className="bg-blue-500 ml-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => handleUploadPhoto(logo_url)}
                >
                  Kaydet
                </button>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-2 rounded"
                  onClick={() => handleSelectPhotoFromPC()}
                >
                  Bilgisayardan Yükle
                </button>
              </div>
            )}
            <table className="table-auto w-full">
              <tbody>
                <tr className="border-b">
                  <td className="py-2 font-semibold text-gray-700">Kampanya Id</td>
                  <td className="py-2 text-gray-600">{data._id}</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 font-semibold text-gray-700">Kampanya Adı</td>
                  <td className="py-2 text-gray-600">{data.name}</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 font-semibold text-gray-700">Marka Açıklaması</td>
                  <td className="py-2 text-gray-600">{data.brand_description}</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 font-semibold text-gray-700">Ülke</td>
                  <td className="py-2 text-gray-600">{data.country}</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 font-semibold text-gray-700">Platform</td>
                  <td className="py-2 text-gray-600">{data.platform}</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 font-semibold text-gray-700">Görünürlük</td>
                  <td className="py-2 text-gray-600">
                    {data.visibility ? "Yayında" : "Yayında değil"}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 font-semibold text-gray-700">Onay Durumu</td>
                  <td className="py-2 text-gray-600">
                    {data.is_verified ? "Onaylı" : "Onaylı Değil"}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 font-semibold text-gray-700">Sektör</td>
                  <td className="py-2 text-gray-600">{data.sector}</td>
                </tr>
                <tr>
                  <td className="py-2 font-semibold text-gray-700">Alt Sektör</td>
                  <td className="py-2 text-gray-600">{data.sub_sector}</td>
                </tr>
                <tr>
                  <td className="py-2 font-semibold text-gray-700">Ürün Gönderimi</td>
                  <td className="py-2 text-gray-600">{barter === true ? "Var" : "Yok"}</td>
                  <button
                    className="bg-blue-500 text-white rounded-md py-2 mt-1 w-full"
                    onClick={() => setIsBarterDropdownOpen(true)}
                  >
                    Güncelle
                  </button>
                </tr>
                {barter && (
                  <>
                    <tr>
                      <td className="py-2 font-semibold text-gray-700">Ürün Gönderim Açıklaması</td>
                      <td className="py-2 text-gray-600">{barterDescription}</td>
                    </tr>
                    <tr>
                      <td className="py-2 font-semibold text-gray-700">Ürün Gönderim Detayları</td>
                      <td className="py-2 text-gray-600">{barterDetails}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            {isBarterDropdownOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-4 rounded shadow-lg w-full max-w-xs mx-auto">
                  <h2 className="text-lg font-bold mb-4">Kampanya Ürün Gönderimini Güncelle</h2>
                  <div className="max-h-96 overflow-y-auto">
                    {" "}
                    {/* max-h-64'ü max-h-96 olarak değiştirdim */}
                    <h3 className="text-md font-bold mb-2">Ürün Gönderimi</h3>
                    <div className="flex flex-row">
                      <label className="flex items-center cursor-pointer">
                        <input
                          type="radio"
                          className="form-radio h-5 w-5 text-blue-600"
                          name="barter"
                          value="true"
                          onChange={() => setNewBarter(true)}
                        />
                        <span className="text-gray-700">Evet</span>
                      </label>
                      <label className="flex items-center cursor-pointer ml-4">
                        <input
                          type="radio"
                          className="form-radio h-5 w-5 text-blue-600"
                          name="barter"
                          value="false"
                          onChange={() => setNewBarter(false)}
                        />
                        <span className="text-gray-700">Hayır</span>
                      </label>
                    </div>
                    {newBarter && (
                      <div>
                        <h3 className="text-md font-bold mb-2 mt-6">Ürün Gönderimi Detayları</h3>
                        <label className="flex items-center cursor-pointer">
                          <textarea
                            className="w-full p-2 border border-gray-200 rounded-md"
                            placeholder="Write barter details"
                            value={newBarterDetails}
                            onChange={(e) => setNewBarterDetails(e.target.value)}
                          />
                        </label>
                        <h3 className="text-md font-bold mb-2">Ürün Gönderimi Açıklaması</h3>
                        <label className="flex items-center cursor-pointer">
                          <textarea
                            className="w-full p-2 border border-gray-200 rounded-md"
                            placeholder="Write barter details"
                            value={newBarterDescription}
                            onChange={(e) => setNewBarterDescription(e.target.value)}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                  <button
                    className="mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                    onClick={() => {
                      handleUpdateBarter(data._id)
                    }}
                  >
                    Kaydet
                  </button>
                  <button
                    className="mt-4 ml-12 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                    onClick={() => setIsBarterDropdownOpen(false)}
                  >
                    Kapat
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="col-span-1 md:col-span-1">
            <div className="bg-white p-4 rounded-lg shadow border border-gray-200 overflow-hidden">
              <h4 className="text-lg font-semibold mb-4 text-gray-700">İçerik Metrikleri</h4>
              <table className="table-auto w-full">
                <tbody>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">İçerik Detayları</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_details)
                        ? data.content_details.join(", ")
                        : data.content_details}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">
                      İçerikte Bahsedilmesi Gerekenler
                    </td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_mentions)
                        ? data.content_mentions.join(", ")
                        : data.content_mentions}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">İçerik Örnek Metni</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_example_text)
                        ? data.content_example_text.join(", ")
                        : data.content_example_text}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">İçerik Metrikleri</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_metrics)
                        ? data.content_metrics.join(", ")
                        : data.content_metrics}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">
                      İçerikte Bahsedilmemesi Gerekenler
                    </td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.mention_awoid)
                        ? data.mention_awoid.join(", ")
                        : data.mention_awoid}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 text-gray-600 truncate">İçerikte Vurgulanması Gereken</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.mention_emphasis)
                        ? data.mention_emphasis.join(", ")
                        : data.mention_emphasis}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-gray-600 truncate">İçerik Hashtagleri</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_hashtags)
                        ? data.content_hashtags.join(", ")
                        : data.content_hashtags}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-gray-600 truncate">İçerik Hesabı</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_account)
                        ? data.content_account.join(", ")
                        : data.content_account}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-gray-600 truncate">İçerik Promosyonları</td>
                    <td className="py-2 text-gray-800 break-words">
                      {Array.isArray(data.content_promotions)
                        ? data.content_promotions.join(", ")
                        : data.content_promotions}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <DetailItem label="Açıklama" value={data.description} />

          {/* Limitations */}
          <div className="col-span-1 md:col-span-1">
            <div className="bg-white p-4 rounded-lg shadow border border-gray-200">
              <h4 className="text-lg font-semibold mb-4 text-gray-700">Segmentasyon</h4>
              <table className="table-auto w-6/12">
                <tbody>
                  <tr className="border-b">
                    <td className="py-2">Cinsiyet</td>
                    <td className="py-2">
                      {data.limitations?.gender ? data.limitations?.gender : "Any"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Yaş</td>
                    <td className="py-2">
                      {data.limitations?.min_age} to {data.limitations?.max_age}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Takipçi</td>
                    <td className="py-2">
                      {data.limitations?.min_follower} to {data.limitations?.max_follower}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">Şehir</td>
                    <td className="py-2">{data.limitations?.city || "Any"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* Campaign Notes */}
          <div className="flex flex-col">
            <div className="bg-white p-4 rounded-lg shadow border border-gray-200">
              <h4 className="text-lg font-semibold mb-4 text-gray-700">Notlar</h4>
              <div className="flex w-full">
                <div className="flex flex-col w-1/2">
                  <textarea
                    className="w-full mt-4 p-2 border border-gray-200 rounded-md"
                    placeholder="Add a note"
                    value={newNotes}
                    onChange={(e) => setNewNotes(e.target.value)}
                  />
                  <div className="flex w-full gap-12 mt-2">
                    <button
                      className="bg-blue-500 text-white rounded-md px-3 py-2 w-full"
                      onClick={() => handleUpdateNote([...notes, newNotes])}
                    >
                      Notları Güncelle
                    </button>
                  </div>
                </div>
                <ul className="ml-3">
                  {notes.map((note, index) => (
                    <li key={index} className="text-gray-600 mt-2">
                      {note}
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: "#005eff", marginLeft: "8px" }}
                        onClick={() => handleDeleteNote(index)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* Campaign Notes */}
          <div className="flex flex-col">
            <div className="bg-white p-4 rounded-lg shadow border border-gray-200">
              <h4 className="text-lg font-semibold mb-4 text-gray-700">Sesli Mesaj Gönder</h4>
              <div className="flex w-full">
                <div className="flex flex-col w-full">
                  <div className="flex w-full gap-12 mt-2">
                    <select
                      className="w-full p-2 border border-gray-200 rounded-md"
                      onChange={(e) => setVoiceMessageState(e.target.value)}
                    >
                      <option value="waiting_content">İçerik Beklenen</option>
                      <option value="waiting_address">Adres Beklenen</option>
                      <option value="invite_new">Davet Edilen</option>
                    </select>
                    <select
                      className="w-full p-2 border border-gray-200 rounded-md"
                      onChange={(e) => setVoiceMessageType(e.target.value)}
                    >
                      <option value="all">Hepsi</option>
                      <option value="unaswered">Cevap Vermeyenler</option>
                    </select>
                    <button
                      className="bg-blue-500 text-white rounded-md px-3 py-2 w-full"
                      onClick={() => handleSendVoiceMessage()}
                      disabled={voiceMessageState === "sending"}
                    >
                      Sesli Mesaj Gönder
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex w-full gap-12 mt-2">
                <p className="text-gray-600 mt-2">{voiceStatus}</p>
              </div>
            </div>
          </div>
        </div>
        <DetailItem label="Reddedilme Sebebi" value={data.rejected_reason || "-"} />
      </div>
      <div>
        {suggestedUsers.length > 0 ? (
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Önerilen Kullanıcılar</h3>
            <div className="overflow-x-auto py-4">
              <div className="flex space-x-4">
                {suggestedUsers.map((user, index) => (
                  <UserCard
                    key={index}
                    user={user}
                    campaign_id={data._id}
                    campaign_type={data.platform}
                  />
                ))}
                <div className="flex-shrink-0 w-60 p-4 bg-white shadow-md rounded-lg border border-gray-200 hover:shadow-xl transition-shadow duration-300">
                  <div onClick={() => setIsApplicationModalOpen(true)}>
                    <img
                      src={"https://via.placeholder.com/150"}
                      alt={"asd"}
                      className="w-16 h-16 rounded-full object-cover mx-auto mb-4"
                    />
                    <div className="flex items-center justify-center mt-4">
                      <button
                        className="text-blue-600 font-semibold cursor-pointer"
                        onClick={() => setIsApplicationModalOpen(true)}
                      >
                        <h3 className="text-center text-lg font-semibold">
                          Yeni Kullanıcı Oluştur
                        </h3>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddApplicationModal
              isOpen={isApplicationModalOpen}
              onClose={() => setIsApplicationModalOpen(false)}
              _id={data._id}
              currency="TRY"
              platform={data.platform.includes("insta") ? "instagram" : "tiktok"}
            />
            <div className="flex justify-center items-center mt-4 space-x-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 disabled:bg-gray-200 disabled:text-gray-500"
                onClick={goToPreviousPage}
                disabled={page === 1}
              >
                Önceki
              </button>
              <span className="text-gray-700">
                Page {page} of {totalPages}
              </span>
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 disabled:bg-gray-200 disabled:text-gray-500"
                onClick={goToNextPage}
                disabled={page === totalPages}
              >
                Sonraki
              </button>
            </div>
          </div>
        ) : (
          <p className="text-center text-gray-600">Önerilen Kullanıcı Yok</p>
        )}
      </div>
    </div>
  )
}

const DetailItem: React.FC<{ label: string; value: string | number }> = ({ label, value }) => (
  <div className="bg-white p-4 rounded-lg shadow border border-gray-200">
    <h5 className="text-md font-semibold mb-1 text-gray-700">{label}</h5>
    <p className="text-gray-600">{value}</p>
  </div>
)

export default CampaignProfile
