import React from "react"

interface SelecterProps {
  title?: string
  options: string[]
  disabled_option: string
  setSelected: (value: string) => void
}

const Selecter: React.FC<SelecterProps> = ({ title, options, disabled_option, setSelected }) => {
  return (
    <div>
      <span className="text-gray-700">{title}</span>
      <select
        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        onChange={(e) => {
          setSelected(e.target.value)
        }}
      >
        {options.map((option) =>
          option === "" ? (
            <option key={option} value={option}>
              {disabled_option}
            </option>
          ) : (
            <option key={option} value={option}>
              {option}
            </option>
          ),
        )}
      </select>
    </div>
  )
}

export default Selecter
