import React from "react"
import Modal from "../../../../components/Modal"

interface ModalFieldProps {
  modalOpen: boolean
  onClose: () => void
  infoState: string
  setInfoState: any
  handleSaveState: any
  handleCloseInfoModal: any
  application: any
}

const EditApplicationNote: React.FC<ModalFieldProps> = ({
  modalOpen,
  onClose,
  infoState,
  setInfoState,
  handleSaveState,
  handleCloseInfoModal,
  application,
}) => {
  return modalOpen ? (
    <div className="flex h-full items-center justify-center absolute">
      <Modal
        onClick={onClose}
        classname="fixed inset-0 bg-gray-600 bg-opacity-50 p-3 flex items-center justify-center z-50"
        classname2="relative bg-white rounded-3xl w-1/2"
      >
        <div className="bg-white p-8 rounded-lg bg-opacity-50 overflow-y-auto h-1/3 w-full space-y-4">
          <h2 className="text-xl font-semibold mb-4">Başvuru Notu</h2>
          <div className="space-y-4 flex flex-col">
            <input
              type="text"
              value={infoState}
              onChange={(e) => setInfoState(e.target.value)}
              placeholder="Yeni not giriniz"
              className="block w-full p-2 border rounded"
            />
            <div className="w-full flex justify-end">
              <button
                className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded"
                onClick={() => handleSaveState(application._id, infoState)}
              >
                Kaydet
              </button>
              <button
                className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded ml-4"
                onClick={handleCloseInfoModal}
              >
                Kapat
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  ) : null
}

export default EditApplicationNote
