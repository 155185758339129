import React from "react"

const getStatusColor = (status) => {
  switch (status) {
    case "answered":
      return "green"
    case "sent":
      return "orange"
    default:
      return "red"
  }
}

const formatDate = (isoDate) => {
  const date = new Date(isoDate)
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")} ${date.getHours().toString().padStart(2, "0")}:${date
    .getMinutes()
    .toString()
    .padStart(2, "0")}`
}

const VoiceMessagesList = ({ record }) => {
  if (!record?.voice_messages?.length) {
    return null
  }

  const lastFiveMessages = [...record.voice_messages].slice(0, 3) // en yenisi yukarıda olsun

  return (
    <div>
      {lastFiveMessages.map((msg, index) => {
        const formattedDate = formatDate(msg.created_at)
        const color = getStatusColor(msg.status)
        return (
          <div key={index} style={{ marginBottom: "5px" }}>
            <span>{formattedDate}</span> <span style={{ color }}>{msg.status}</span>
          </div>
        )
      })}
      ...
    </div>
  )
}

export default VoiceMessagesList
