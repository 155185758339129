import React, { useEffect, useState } from "react"
import Modal from "../../../../components/Modal"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { CheckIcon } from "@heroicons/react/20/solid"
import { useTranslation } from "react-i18next"
import ClearIcon from "../../../../assets/images/icons/clear.svg"
import { TAbrandUserCategories } from "../../../../services/brandAPI"

interface SubCategory {
  category: string
  count: number
}

interface Category {
  main_category: string
  sub_categories: SubCategory[]
}

interface CategoryModalProps {
  title: string
  isOpen: boolean
  onClose: () => void
  onSelect: (category: string, _id?: string) => void
  selectedValue?: { category?: string }
}

const CategoryModal: React.FC<CategoryModalProps> = ({
  title,
  isOpen,
  onClose,
  onSelect,
  selectedValue,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const { t } = useTranslation()
  const [categories, setCategories] = useState<Category[]>([])

  useEffect(() => {
    fetchUserCategories()
    // eslint-disable-next-line
  }, [])

  const fetchUserCategories = async () => {
    const response = await TAbrandUserCategories()
    if (response && response.data) {
      setCategories(response.data)
    }
  }

  // Filter logic: only keep subcategories that match searchTerm;
  // remove main categories that have 0 subcategories left.
  const filteredCategories = categories
    .map((category) => {
      const filteredSubs = category.sub_categories.filter((sub) =>
        sub.category.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      return { ...category, sub_categories: filteredSubs }
    })
    .filter((category) => category.sub_categories.length > 0)

  return (
    <>
      {isOpen && (
        <div className="flex w-full items-center justify-center">
          <Modal
            classname="fixed inset-0 bg-gray-800 bg-opacity-50 p-3 flex items-center justify-end z-50"
            classname2="relative bg-white rounded-3xl w-full md:w-1/2 h-full"
            onClick={onClose}
          >
            <div className="flex flex-col justify-between w-full h-full items-start px-8 pt-8 overflow-y-auto">
              <div className="w-full space-y-4">
                {/* Header */}
                <div className="flex flex-row w-full justify-between items-center mb-4">
                  <span className="text-lg font-semibold">{title}</span>
                  <img
                    src={ClearIcon}
                    alt="Close"
                    className="w-6 h-6 p-1 cursor-pointer bg-gray-500 bg-opacity-10 rounded-lg"
                    onClick={onClose}
                  />
                </div>

                {/* Search Input */}
                <div className="relative w-full">
                  <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Kategori Ara"
                    className="w-full pl-10 pr-4 py-3 bg-gray-100 rounded-2xl border-none focus:ring-0 focus:outline-none"
                  />
                </div>

                {/* Categories List */}
                <div className="flex-1 overflow-y-auto">
                  {filteredCategories.length === 0 ? (
                    <div className="px-4 py-3 text-sm text-gray-500 text-center">
                      Kategori Bulunamadı
                    </div>
                  ) : (
                    filteredCategories.map((category) => {
                      // Fallback to original main_category if translation is missing
                      const mainCategoryLabel = t(
                        `userCategory.${category.main_category}`,
                        category.main_category,
                      )
                      return (
                        <div key={category.main_category} className="mb-4">
                          <div className="px-4 py-2 text-sm font-medium text-gray-500 bg-gray-50 rounded-t-lg">
                            {mainCategoryLabel}
                          </div>
                          <div className="bg-white rounded-b-lg border-x border-b divide-y">
                            {category.sub_categories.map((subCategory) => {
                              // Fallback to original subCategory.category if translation is missing
                              const subCategoryLabel = t(
                                `userCategory.${subCategory.category}`,
                                subCategory.category,
                              )
                              return (
                                <div
                                  key={subCategory.category}
                                  onClick={() => {
                                    onSelect(subCategory.category)
                                    onClose()
                                  }}
                                  className={`flex items-center justify-between px-4 py-3 text-sm cursor-pointer hover:bg-gray-50 transition-colors ${
                                    selectedValue?.category === subCategory.category
                                      ? "bg-pink-50 hover:bg-pink-50"
                                      : ""
                                  }`}
                                >
                                  <span className="flex-grow">
                                    {subCategoryLabel} ({subCategory.count})
                                  </span>
                                  <div
                                    className={`w-5 h-5 flex items-center justify-center rounded-full border ${
                                      selectedValue?.category === subCategory.category
                                        ? "bg-pink-500 border-pink-500"
                                        : "border-gray-300"
                                    }`}
                                  >
                                    {selectedValue?.category === subCategory.category && (
                                      <CheckIcon className="w-3 h-3 text-white" />
                                    )}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  )
}

export default CategoryModal
