import React from "react"
import DatePicker from "react-datepicker"

interface DatePickerProps {
  title: string
  selected1: Date | null
  selected2: Date | null
  onChange1: (date: Date | null) => void
  onChange2: (date: Date | null) => void
  placeholderText1: string
  placeholderText2: string
}

const DatePick: React.FC<DatePickerProps> = ({
  title,
  selected1,
  onChange1,
  placeholderText1,
  selected2,
  onChange2,
  placeholderText2,
}) => {
  return (
    <div className="flex flex-col w-1/5 mb-4">
      <h2 className="text-md font-semibold">{title}</h2>
      <DatePicker
        selected={selected1}
        onChange={onChange1}
        className="w-full p-2 border border-gray-200 rounded-md mb-1"
        placeholderText={placeholderText1}
      />
      <DatePicker
        selected={selected2}
        onChange={onChange2}
        className="w-full p-2 border border-gray-200 rounded-md mb-1"
        placeholderText={placeholderText2}
      />
    </div>
  )
}

export default DatePick
