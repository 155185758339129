import apiClient from "./axiosInstance"

export const TAdoVisibleCampaign = async (id: string, visibility: string) => {
  const query = new URLSearchParams({
    id,
    visibility,
  })

  const response = await apiClient.post(`/admin/campaign/visible?${query}`, {}, {})

  if (response.status === 200) {
    return response
  }
}
export const TAfindCampaign = async (data: any) => {
  let query = ""
  if (data.name) {
    query = `?name=${data.name}`
  }

  const response = await apiClient.get(`/admin/campaign/get${query}`, {})

  if (response.status === 200) {
    return response
  } else {
    throw new Error("Find Campaign failed")
  }
}

export const TAfindApprovedCampaigns = async (page: number, perPage: number) => {
  const response = await apiClient.get(
    `/admin/campaign/getverifiedcampaign?page=${page}&perPage=${perPage}`,
    {},
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Campaign failed")
  }
}

export const TAfindCampaignById = async (_id: string) => {
  const response = await apiClient.get(`/admin/campaign/_id/${_id}`, {})
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find User failed")
  }
}

export const TAsetVisibility = async (_id: string, status: boolean) => {
  const response = await apiClient.get(
    `/admin/campaign/set-visibilty?status=${status}&_id=${_id}`,
    {},
  )
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Campaign failed")
  }
}

export const TAfindAllCampaigns = async (
  created_at: string,
  country: string,
  platform: string,
  visibility: string,
  max_cost: string,
  gender: string,
  min_follower: string,
  max_follower: string,
  min_age: string,
  max_age: string,
  sortBy: string,
  sortOrder: boolean,
  page: number,
  perPage: number,
  campaign: string,
  classification: string,
) => {
  const response = await apiClient.get(
    `/admin/campaign/getall?created_at=${encodeURIComponent(
      created_at,
    )}&country=${encodeURIComponent(country)}&platform=${encodeURIComponent(
      platform,
    )}&visibility=${encodeURIComponent(visibility)}&max_cost=${encodeURIComponent(
      max_cost,
    )}&gender=${encodeURIComponent(gender)}&min_follower=${encodeURIComponent(
      min_follower,
    )}&max_follower=${encodeURIComponent(max_follower)}&min_age=${encodeURIComponent(
      min_age,
    )}&max_age=${encodeURIComponent(max_age)}&sortBy=${encodeURIComponent(
      sortBy,
    )}&sortOrder=${encodeURIComponent(sortOrder)}&page=${encodeURIComponent(
      page,
    )}&perPage=${encodeURIComponent(perPage)}&campaign=${encodeURIComponent(
      campaign,
    )}&classification=${encodeURIComponent(classification)}`,
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Campaign failed")
  }
}

export const TAfindApprovalCampaign = async () => {
  const response = await apiClient.get("/admin/campaign/getverificationcampaign", {})

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Find Campaign failed")
  }
}

export const TAdoApprovalCampaign = async (status: string, rejected_reason: string, id: string) => {
  const query = new URLSearchParams({
    id,
    status,
    rejected_reason,
  })

  const response = await apiClient.post(`/admin/campaign/verificate?${query}`)

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Approval Campaign failed")
  }
}

export const TAupdateCampaignNotes = async (id: string, notes: string[]) => {
  const response = await apiClient.put(`/admin/campaign/updatenote/${id}`, { notes })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update Campaign notes failed")
  }
}

export const TAsendVoiceMessage = async (campaignId: string, state: string, type: string) => {
  const response = await apiClient.post("/admin/campaign/send-voice-message/", {
    campaignId,
    state,
    type,
  })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Send Voice Message failed")
  }
}

export const TAupdateClassification = async (id: string, classification: string) => {
  const response = await apiClient.post("/admin/campaign/set-campaign-classification", {
    campaignId: id,
    classification,
  })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update Campaign classification failed")
  }
}

export const TAspamCampaign = async (_id: string, is_spam: string) => {
  const response = await apiClient.put(`/admin/campaign/setspamcampaign/${_id}/${is_spam}`, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Approval Campaign failed")
  }
}

export const TAupdateCampaign = async (id: string, data: any) => {
  const response = await apiClient.put(`/admin/campaign/updatecampaign/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Approval Campaign failed")
  }
}

export const TAupdateCampaignLogo = async (id: string, data: any) => {
  const response = await apiClient.put(`/admin/campaign/updatecoverphoto/${id}`, data, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent></calculated>",
    },
  })

  return response
}

export const TAcampaignLimitationApplications = async (
  id: string,
  page: number,
  perPage: number,
) => {
  const response = await apiClient.get(
    `/admin/campaign/suggested-users?campaignId=${id}&page=${page}&perPage=${perPage}`,
    {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent></calculated>",
      },
    },
  )

  return response
}
