import React, { useState } from "react"
import Modal from "./Modal"

interface UpdateUserBalanceProps {
  modalOpen: boolean
  onClose: () => void
  newBalance: number
  setNewBalance: React.Dispatch<React.SetStateAction<number>>
  operation: string
  setOperation: React.Dispatch<React.SetStateAction<string>>
  handleChangeBalance: (newBalance: number, operation: string) => void
}

const UpdateUserBalance: React.FC<UpdateUserBalanceProps> = ({
  modalOpen,
  onClose,
  newBalance,
  setNewBalance,
  operation,
  setOperation,
  handleChangeBalance,
}) => {
  const [error, setError] = useState("")

  const formControl = (newBalance: number, operation: string) => {
    if (isNaN(newBalance) || newBalance < 0) {
      setError("Geçerli bir bakiye giriniz!")
      return false
    }
    if (operation.length < 1) {
      setError("Geçerli bir operasyon seçiniz!")
      return false
    }
    return true
  }

  const handleBalanceChange = () => {
    const isValid = formControl(newBalance, operation)
    try {
      if (isValid) {
        handleChangeBalance(newBalance, operation)
        setError("")
      }
    } catch (error) {
      console.log("error: ", error)
    }
  }

  return modalOpen ? (
    <div className="flex h-full items-center justify-center absolute">
      <Modal
        onClick={onClose}
        classname="fixed inset-0 bg-opacity-50 p-3 flex items-center justify-center z-50"
        classname2="relative bg-white rounded-3xl w-auto"
      >
        <div>
          <div className="bg-white p-8 rounded-lg shadow-md overflow-y-auto h-1/3 max-w-md w-full space-y-4">
            <h2 className="text-xl font-semibold mb-4">Kullanıcı Bakiyesini Güncelle</h2>
            <input
              type="number"
              value={newBalance}
              className="block w-full p-2 border rounded"
              onChange={(e) => setNewBalance(e.target.valueAsNumber)}
            />
            <select
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              onChange={(e) => setOperation(e.target.value)}
            >
              <option key={""} value={""}>
                Operasyon Seçin
              </option>
              <option key={"qnb_dijital_kopru_bonus"} value={"qnb_dijital_kopru_bonus"}>
                QNB Dijital Köprü Bonus
              </option>
              <option key={"user_admin_change"} value={"user_admin_change"}>
                Admin Bakiye Güncelleme
              </option>
            </select>
            <div className="w-full flex justify-end">
              <button
                className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-lg"
                onClick={handleBalanceChange}
              >
                Güncelle
              </button>
              <button
                className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg ml-4"
                onClick={() => onClose()}
              >
                Kapat
              </button>
            </div>
            {error && <div className="flex justify-center">{error}</div>}
          </div>
        </div>
      </Modal>
    </div>
  ) : null
}

export default UpdateUserBalance
