import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitle } from "../../../redux/store/themeConfigSlice"
import {
  TAfindAllCampaigns,
  TAsetVisibility,
  TAspamCampaign,
  TAupdateCampaignNotes,
  TAupdateClassification,
} from "../../../services/campaignsAPI"
import { Campaign } from "../../../types/CampaignData"
import { DataTable, DataTableSortStatus } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import {
  selectCampaignFilters,
  setCampaignFilters,
} from "../../../redux/store/filters/campaign/campaignFilterSlice"
import { generateColumn } from "../../User/bannedUsers/components/columns"
import { columnConfig } from "./components/columns"
import { handleSorting } from "../../User/getAllUsers/components/Functions"
import CategoryModal from "./components/CategoryModal"
import ClassificationModal from "./components/ClassificationModal"

const fetchData = async (
  created_at: string,
  country: string,
  platform: string,
  visibility: string,
  max_cost: string,
  gender: string,
  min_follower: string,
  max_follower: string,
  min_age: string,
  max_age: string,
  sortBy: string,
  sortOrder: boolean,
  page: number,
  perPage: number,
  campaign: string,
  classification: string,
) => {
  try {
    const response = await TAfindAllCampaigns(
      created_at,
      country,
      platform,
      visibility,
      max_cost,
      gender,
      min_follower,
      max_follower,
      min_age,
      max_age,
      sortBy,
      sortOrder,
      page,
      perPage,
      campaign,
      classification,
    )
    if (response && Array.isArray(response.campaigns)) {
      const totalRecords = response.totalDocuments
      const totalLength = response.campaigns.length
      const data = response.campaigns.map((item: any, index: any) => {
        return {
          id: totalLength - index,
          _id: item.details._id,
          ...item,
        }
      })
      return { data, totalRecords }
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

function AllCampaign() {
  const dispatch = useDispatch()
  const campaignFilters = useSelector(selectCampaignFilters)
  useEffect(() => {
    dispatch(setPageTitle("All Campaigns"))
    const initialFilters = campaignFilters
    setPlatform(initialFilters.platform)
    setCreated_at(initialFilters.created_at)
    setVisibility(initialFilters.visibility)
    setGender(initialFilters.gender)
    setCountry(initialFilters.country)
    setMax_follower(initialFilters.max_follower)
    setMin_follower(initialFilters.min_follower)
    setMax_age(initialFilters.max_age)
    setMin_age(initialFilters.min_age)
    setMax_cost(initialFilters.max_cost)
    setCampaignName(initialFilters.campaignName)
    setClassification(initialFilters.classification)
  }, [dispatch])

  const [campaignData, setCampaignData] = useState([] as any[])
  const [page, setPage] = useState(1)
  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [totalRecords, setTotalRecords] = useState(0)
  const [initialRecords, setInitialRecords] = useState(sortBy(campaignData, "id"))
  const [error, setError] = useState<string | null>(null)
  const sortStatus: DataTableSortStatus = { columnAccessor: "id", direction: "asc" }
  const [sortByColumn, setSortByColumn] = useState("")
  const [sortOrder, setSortOrder] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [platform, setPlatform] = useState("")
  const [created_at, setCreated_at] = useState("")
  const [visibility, setVisibility] = useState("")
  const [gender, setGender] = useState("")
  const [country, setCountry] = useState("")
  const [max_follower, setMax_follower] = useState("")
  const [min_follower, setMin_follower] = useState("")
  const [max_age, setMax_age] = useState("")
  const [min_age, setMin_age] = useState("")
  const [max_cost, setMax_cost] = useState("")
  const [campaignName, setCampaignName] = useState("")
  const [notes, setNotes] = useState("")
  const [campaignNotes, setCampaignNotes] = useState([""])
  const [editMode, setEditMode] = useState<EditModeState>({})
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false)
  const [isOpenClassificationModal, setIsOpenClassificationModal] = useState(false)
  const [classification, setClassification] = useState("")
  const [selectedId, setSelectedId] = useState("")
  const [selectedClassification, setSelectedClassification] = useState([
    {
      main_category: {
        category: "",
        confidence: 0,
      },
      sub_categories: [
        {
          category: "",
          confidence: 0,
        },
      ],
    },
  ])

  interface EditModeState {
    [key: string]: boolean
  }

  useEffect(() => {
    setLoading(true)
    const loadCampaigns = async () => {
      try {
        const response = await fetchData(
          created_at,
          country,
          platform,
          visibility,
          max_cost,
          gender,
          min_follower,
          max_follower,
          min_age,
          max_age,
          sortByColumn,
          sortOrder,
          page,
          pageSize,
          campaignName,
          classification,
        )
        if (response !== undefined) {
          setTotalRecords(response.totalRecords)
          setCampaignData(response.data)
          setInitialRecords(response.data)
          setError(null)
        }
      } catch (error) {
        setError("No data found")
      } finally {
        setLoading(false)
      }
    }

    loadCampaigns()
  }, [
    created_at,
    country,
    platform,
    visibility,
    max_cost,
    gender,
    min_follower,
    max_follower,
    min_age,
    max_age,
    sortByColumn,
    sortOrder,
    page,
    pageSize,
    campaignName,
    classification,
  ])

  useEffect(() => {
    setPage(1)
  }, [
    pageSize,
    platform,
    created_at,
    visibility,
    gender,
    country,
    max_follower,
    min_follower,
    max_age,
    min_age,
    max_cost,
    sortByColumn,
    sortOrder,
    campaignName,
    classification,
  ])

  const handleCampaignSelect = (selectedCampaign: any) => {
    setCampaignName(selectedCampaign.name)
    dispatch(setCampaignFilters({ campaignName: selectedCampaign.name }))
  }

  useEffect(() => {
    const handleClick = () => {
      setIsDropdownOpen(false)
    }
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  const handleSpamCampaign = (_id: any) => {
    TAspamCampaign(_id, "true")
  }
  const handleFilterChange = (filterName: any, value: any, setState: any) => {
    setState(value)
    dispatch(setCampaignFilters({ ...campaignFilters, [filterName]: value }))
  }

  const handleToggleVisibility = async (_id: any, status: any) => {
    try {
      const res = await TAsetVisibility(_id, status)
      if (res) {
        const updatedCampaigns = initialRecords.map((campaign) => {
          if (campaign._id === _id) {
            return { ...campaign, visibility: status === "true" ? true : false }
          }
          return campaign
        })
        setInitialRecords([...updatedCampaigns])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleClassificationChange = async (_id: string, classification_string: any) => {
    try {
      const res = await TAupdateClassification(_id, classification_string)
      if (res) {
        const main_category = classification_string.split(":")[0]
        const sub_category = classification_string.split(":")[1]
        const classificationObject = {
          main_category: {
            category: main_category,
            confidence: 1,
          },
          sub_categories: [
            {
              category: sub_category,
              confidence: 1,
            },
          ],
        }
        const updatedCampaigns = initialRecords.map((campaign) => {
          if (campaign._id === _id) {
            return { ...campaign, classification: [classificationObject] }
          }
          return campaign
        })
        setInitialRecords([...updatedCampaigns])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleUpdateNote = async (_id: string, campaignNotes: string[]) => {
    try {
      const campaign = await TAupdateCampaignNotes(_id, campaignNotes)
      if (campaign) {
        alert("Note updated successfully")
        setEditMode((prev) => ({ ...prev, [_id]: false }))
      }
    } catch (error) {
      console.error(error)
      alert("Failed to update the note")
    }
  }

  const handleDeleteNote = (_id: any, index: any) => {
    const newNotes = campaignNotes.filter((note, noteIndex) => noteIndex !== index)
    handleUpdateNote(_id, newNotes)
  }

  const toggleEditMode = (_id: string) => {
    setEditMode((prev) => ({ ...prev, [_id]: true }))
  }

  const columns = columnConfig({
    handleToggleVisibility,
    loading,
    setSortByColumn,
    handleSpamCampaign,
    editMode,
    handleDeleteNote,
    setNotes,
    notes,
    handleUpdateNote,
    toggleEditMode,
    setCampaignNotes,
    setIsOpenClassificationModal,
    setSelectedId,
    setSelectedClassification,
  }).map((col) => generateColumn(col.accessor, col.title, col.sortable, col.render))

  return (
    <div className="panel">
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="flex md:items-center md:flex-row flex-col">
        <div className="flex flex-col md:flex-row gap-5">
          <div className="ml-4 py-2">
            <h3 className="font-bold">Platform</h3>
            {["insta-post", "insta-reels", "insta-story", "tiktok", ""].map((value) => (
              <div key={value} className="form-check flex flex-row">
                <input
                  type="radio"
                  id={value}
                  name="platform"
                  value={value}
                  checked={platform === value}
                  onChange={(e) => handleFilterChange("platform", e.target.value, setPlatform)}
                />
                <label className="form-check-label ml-2 mt-2" htmlFor={value}>
                  {value === "" ? "any" : value}
                </label>
              </div>
            ))}
          </div>
          <div className="py-2">
            <h3 className="ml-2 font-bold">Oluşturulma Tarihi</h3>
            {["last_3_months", "last_month", "last_week", ""].map((value) => (
              <div key={value} className="form-check flex flex-row">
                <input
                  type="radio"
                  id={value}
                  name="created_at"
                  value={value}
                  checked={created_at === value}
                  onChange={(e) => handleFilterChange("created_at", e.target.value, setCreated_at)}
                />
                <label className="form-check-label ml-2 mt-2" htmlFor={value}>
                  {value === "" ? "any" : value}
                </label>
              </div>
            ))}
          </div>
          <div className="py-2">
            <h3 className="ml-2 font-bold">Görünürlük</h3>
            {["false", "true", ""].map((value) => (
              <div key={value} className="form-check flex flex-row">
                <input
                  type="radio"
                  id={`visibility-${value}`}
                  name="visibility"
                  value={value}
                  checked={visibility === value}
                  onChange={(e) => handleFilterChange("visibility", e.target.value, setVisibility)}
                />
                <label className="form-check-label ml-2 mt-2" htmlFor={`visibility-${value}`}>
                  {value === "" ? "any" : value}
                </label>
              </div>
            ))}
          </div>
          <div className="py-2">
            <h3 className="ml-2 font-bold">Cinsiyet</h3>
            {["female", "male", ""].map((value) => (
              <div key={value} className="form-check flex flex-row">
                <input
                  type="radio"
                  id={value}
                  name="gender"
                  value={value}
                  checked={gender === value}
                  onChange={(e) => handleFilterChange("gender", e.target.value, setGender)}
                />
                <label className="form-check-label ml-2 mt-2" htmlFor={value}>
                  {value === "" ? "any" : value}
                </label>
              </div>
            ))}
          </div>
          <div className="py-2">
            <h3 className="ml-2 font-bold">Ülke</h3>
            {["others", "TR", ""].map((value) => (
              <div key={value} className="form-check flex flex-row">
                <input
                  type="radio"
                  id={value}
                  name="country"
                  value={value}
                  checked={country === value}
                  onChange={(e) => handleFilterChange("country", e.target.value, setCountry)}
                />
                <label className="form-check-label ml-2 mt-2" htmlFor={value}>
                  {value === "" ? "any" : value}
                </label>
              </div>
            ))}
          </div>
          <div>
            <div className="flex ml-4">
              <div className="flex flex-col">
                <div className="ml-4 py-2">
                  <h3 className="font-bold">Max Takipçi</h3>
                  <div className="flex flex-row items-center mt-2">
                    <input
                      type="number"
                      className="bg-gray-100 rounded-md px-3 py-2 w-20 focus:outline-none focus:ring focus:border-blue-300"
                      value={max_follower}
                      onChange={(e) =>
                        handleFilterChange("max_follower", e.target.value, setMax_follower)
                      }
                    />
                  </div>
                </div>
                <div className="ml-4 py-2">
                  <h3 className="font-bold">Min Takipçi</h3>
                  <div className="flex flex-row items-center mt-2">
                    <input
                      type="number"
                      className="bg-gray-100 rounded-md px-3 py-2 w-20 focus:outline-none focus:ring focus:border-blue-300"
                      value={min_follower}
                      onChange={(e) =>
                        handleFilterChange("min_follower", e.target.value, setMin_follower)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="ml-4 py-2">
                  <h3 className="font-bold">Max Yaş</h3>
                  <div className="flex flex-row items-center mt-2">
                    <input
                      type="number"
                      className="bg-gray-100 rounded-md px-3 py-2 w-20 focus:outline-none focus:ring focus:border-blue-300"
                      value={max_age}
                      onChange={(e) => handleFilterChange("max_age", e.target.value, setMax_age)}
                    />
                  </div>
                </div>
                <div className="ml-4 py-2">
                  <h3 className="font-bold">Min Yaş</h3>
                  <div className="flex flex-row items-center mt-2">
                    <input
                      type="number"
                      className="bg-gray-100 rounded-md px-3 py-2 w-20 focus:outline-none focus:ring focus:border-blue-300"
                      value={min_age}
                      onChange={(e) => handleFilterChange("min_age", e.target.value, setMin_age)}
                    />
                  </div>
                </div>
              </div>
              <div className="ml-4 py-2">
                <h3 className="font-bold">Max Tutar</h3>
                <div className="flex flex-row items-center mt-2">
                  <input
                    type="number"
                    className="bg-gray-100 rounded-md px-3 py-2 w-20 focus:outline-none focus:ring focus:border-blue-300"
                    value={max_cost}
                    onChange={(e) => handleFilterChange("max_cost", e.target.value, setMax_cost)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="mb-4">
              <div className="form-check flex flex-row">
                <input
                  type="text"
                  className="form-input w-auto"
                  name="campaignName"
                  placeholder="Kampanya ismi ara"
                  value={campaignName}
                  onChange={(e) =>
                    handleFilterChange("campaignName", e.target.value, setCampaignName)
                  }
                />
                <label className="form-check-label ml-2 mt-2"></label>
              </div>
              {isDropdownOpen && initialRecords.length > 0 && (
                <div className="w-auto absolute bg-white border border-gray-300 rounded z-10">
                  {initialRecords.slice(0, 4).map((match: Campaign) => (
                    <div
                      className="p-2 border-b border-gray-300 hover:bg-gray-100"
                      key={match._id}
                      onClick={() => handleCampaignSelect(match)}
                    >
                      {match.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              className="flex items-center gap-2 px-4 py-1.5 border border-gray-300 rounded-xl cursor-pointer hover:bg-gray-100"
              onClick={() => setIsOpenCategoryModal(true)}
            >
              <span>{classification ? classification : "Kampanyaları Filtrele"}</span>
            </div>
          </div>
        </div>
      </div>
      <CategoryModal
        title="Kampanyaları Filtrele"
        isOpen={isOpenCategoryModal}
        onClose={() => setIsOpenCategoryModal(false)}
        onSelect={(classification) =>
          handleFilterChange("classification", classification, setClassification)
        }
        selectedValue={{ category: classification }}
      />
      <ClassificationModal
        title="Kampanya Sınıflandırma"
        isOpen={isOpenClassificationModal}
        onClose={() => setIsOpenClassificationModal(false)}
        onSelect={() => handleClassificationChange(selectedId, selectedClassification)}
        selectedClassification={selectedClassification}
        setSelectedClassification={setSelectedClassification}
      />

      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={initialRecords}
            columns={columns}
            totalRecords={totalRecords}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={(e) =>
              handleSorting(e.columnAccessor, setSortByColumn, setSortOrder)
            }
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default AllCampaign
