import React, { useEffect, useState } from "react"
import { TAfindCampaignById } from "../../../services/campaignsAPI"
import { TAfindApplicationByCampaignId } from "../../../services/applicationAPI"
import { useParams } from "react-router-dom"
import { Campaign } from "../../../types/CampaignData"
import { CampaignProfile } from "../../../components/CampaignProfile"
import AddApplicationModal from "../../../components/AddApplicationModal"
import DetailedApplicationsTable from "../../../components/DetailedApplicationsTabe"

const applicationStates = [
  "invite_new",
  "invite_success",
  "ugc_content",
  "ugc_rejected",
  "first_application",
  "waiting_address",
  "address_to_approve",
  "waiting_content",
  "content_approved",
  "content_to_share",
  "content_shared",
  "application_done",
  "content_rejected",
  "address_rejected",
  "account_rejected",
  "brand_canceled",
  "user_canceled",
]
export const applicationStateLabels = {
  invite_new: "Davet Edilen Profiller",
  invite_success: "Daveti Kabul Eden Profiller",
  ugc_content: "UGC İçerikler",
  ugc_rejected: "UGC İçerik Reddedildi",
  first_application: "Başvuran Profiller",
  waiting_address: "Adres Beklenen Profiller",
  address_to_approve: "Adres Onayı Bekleniyor",
  waiting_content: "İçerik Bekleniyor",
  content_approved: "Onaylanan İçerikler",
  content_to_share: "Paylaşılacak İçerik",
  content_shared: "Paylaşılan İçerikler",
  application_done: "Başvuru Tamamlandı",
  content_rejected: "İçerik Reddedildi",
  address_rejected: "Adres Reddedildi",
  account_rejected: "Hesap Reddedildi",
  brand_canceled: "Marka Tarafından İptal Edildi",
  user_canceled: "Kullanıcı Tarafından İptal Edildi",
}

const FindCampaign = () => {
  const { _id } = useParams<{ _id: string }>()
  const [selectedState, setSelectedState] = useState("first_application")
  const [error, setError] = useState<string | null>(null)
  const [campaign, setCampaign] = useState<Campaign>()
  const [applications, setApplications] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  useEffect(() => {
    if (_id) {
      const fetchData = async () => {
        setLoading(true)
        try {
          const campaign_data = await TAfindCampaignById(_id)
          const application_data = await TAfindApplicationByCampaignId(_id, selectedState)
          setCampaign(campaign_data)
          setApplications(application_data.applications)
          setLoading(false)
        } catch (error: any) {
          setError(error.message)
        }
      }
      fetchData()
    }
  }, [_id, selectedState])

  const reload = async (e: any) => {
    const application_data = await TAfindApplicationByCampaignId(_id, selectedState)
    setApplications(application_data.applications)
  }

  if (loading) {
    return (
      <div className="flex flex-col lg:flex-row justify-between items-start min-h-screen bg-cover bg-center relative">
        <div className="w-full ">loading</div>
      </div>
    )
  } else {
    return (
      <div className="flex flex-col lg:flex-col justify-between items-start min-h-screen bg-cover bg-center relative">
        <div className="w-full ">{campaign && <CampaignProfile {...campaign} />}</div>
        <div className="w-full">
          <button className="bg-indigo-100 px-8 py-4 rounded-md my-4" onClick={handleOpenModal}>
            Yeni Başvuru Oluştur
          </button>
          <AddApplicationModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            _id={_id}
            currency={campaign?.currency || "TRY"}
            platform={campaign?.platform.includes("insta") ? "instagram" : "tiktok"}
          />

          <label className="text-lg mt-5">Başvurular</label>
          <div className="flex flex-wrap gap-4">
            {applicationStates.map((state) => (
              <div
                key={state}
                className={`py-2 px-4 rounded-md cursor-pointer transition ${
                  selectedState === state
                    ? "bg-gray-700 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
                onClick={() => setSelectedState(state)}
              >
                {applicationStateLabels[state] || state}
              </div>
            ))}
          </div>
          <DetailedApplicationsTable
            application_data={applications}
            reload={reload}
            selectedState={selectedState}
          />
        </div>

        <form className="w-1/4 absolute top-5 right-6">
          {error && <p className="text-red-500 text-sm text-center">{error}</p>}
          <div className="flex justify-center">
            <div className="relative inline-flex"></div>
          </div>
        </form>
      </div>
    )
  }
}

export default FindCampaign
