import { faMars, faVenus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import {
  TAupdateApplication,
  TAassignUserToApplication,
  TAapplicationIsCalled,
  TAapplicationInfoState,
} from "../services/applicationAPI"
import ApplicationProfileDetailModal from "./ApplicationProfileDetail"
import { UpdateApplicationModal } from "./UpdateApplicationModal"
import { numFixer } from "../pages/User/getAllUsers/components/Functions"
import { CheckBadgeIcon } from "@heroicons/react/20/solid"
import EditUserPriceBrand from "../pages/Campaign/findCampaign/components/editPriceUserBrand"
import AssingUser from "../pages/Campaign/findCampaign/components/assignUser"
import EditApplicationNote from "../pages/Campaign/findCampaign/components/editApplicationNote"
import { selectUser } from "../redux/store/auth/authSlice"
import { useSelector } from "react-redux"
import VoiceMessagesList from "./VoiceMessagesList"
interface DetailedApplicationsTableProps {
  selectedState: string
  application_data: any
  reload: any
}

const DetailedApplicationsTable: React.FC<DetailedApplicationsTableProps> = ({
  selectedState,
  application_data,
  reload,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newPricingBrand, setNewPricingBrand] = useState<number>(0)
  const [newPricingUser, setNewPricingUser] = useState<number>(0)
  const [application, setApplication] = useState<any>({})
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [selectedApp, setSelectedApp] = useState<any>({})
  const [assignUserModal, setAssignUserModal] = useState(false)
  const [user_id, setUserId] = useState("")
  const [selectedUser, setSelectedUser] = useState(null)
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false)
  const [stateModalOpen, setStateModalOpen] = useState(false)
  const [infoState, setInfoState] = useState("")
  const admin = useSelector(selectUser)
  const clearAdmin = admin.replace(/['"]+/g, "")

  const handleOpenPricingModal = (application: any) => {
    setApplication(application)
    setNewPricingBrand(application.price_brand)
    setNewPricingUser(application.price_user)
    setIsModalOpen(true)
  }

  const handleOpenUpdateModal = (app: any) => {
    setSelectedApp(app)
    setIsUpdateModalOpen(true)
  }

  const handleCloseUpdateModal = () => {
    setSelectedApp({})
    setIsUpdateModalOpen(false)
  }

  const handleClosePricingModal = () => {
    setIsModalOpen(false)
    setApplication({})
  }

  const handleOpenInfoModal = (application: any) => {
    setApplication(application)
    setInfoState(application.info_state)
    setStateModalOpen(true)
  }

  const handleCloseInfoModal = () => {
    setIsModalOpen(false)
    setApplication({})
  }

  const handleSavePricingData = async (app: any) => {
    try {
      const response = await TAupdateApplication(
        { price_brand: newPricingBrand, price_user: newPricingUser },
        app._id,
      )
      if (response) {
        setIsModalOpen(false)
      }
    } catch (error) {
      alert("Error updating application")
    }
  }

  const handleAssignUser = async (application_id: string, user_id: string) => {
    try {
      const response = await TAassignUserToApplication(application_id, user_id)
      if (response.status === 200) {
        alert("User assigned to application successfully")
        reload()
      }
    } catch (error) {
      alert("Error assigning user to application")
    }
  }

  const handleIsCalled = async (
    application_id: string,
    is_called: boolean,
    is_called_by: string,
  ) => {
    try {
      const response = await TAapplicationIsCalled(application_id, is_called, is_called_by)
      if (response) {
        setIsModalOpen(false)
      }
    } catch (error) {
      alert("Error updating application")
    }
  }

  const handleInfoState = async (application_id: string, info_state: string) => {
    try {
      const response = await TAapplicationInfoState(application_id, info_state)
      if (response) {
        setStateModalOpen(false)
      }
    } catch (error) {
      alert("Error updating application")
    }
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full table-auto text-sm">
        <thead className="bg-gray-200">
          <tr>
            <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">
              Kullanıcı
            </th>
            <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">
              Durumu Güncelle
            </th>
            <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">
              Kullanıcı Ata
            </th>
            <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">
              Takipçi Sayısı
            </th>
            <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">Şehir</th>
            <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">Yaş</th>
            <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">
              Cinsiyet
            </th>
            <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">
              Etkileşim Oranı
            </th>
            <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">
              Kullanıcı/Marka Tutarı
            </th>
            {selectedState === "waiting_content" && (
              <>
                <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">
                  Aranma Durumu
                </th>
                <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">
                  Ekstra Not
                </th>
              </>
            )}

            {["waiting_content", "invite_new", "waiting_address"].includes(selectedState) && (
              <>
                <th className="border px-2 py-2 text-center align-middle whitespace-nowrap">
                  Sesli Mesajlar
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <ApplicationProfileDetailModal
            data={selectedUser}
            isOpen={isOpenDetailModal}
            onClose={() => {
              setIsOpenDetailModal(false)
            }}
          />
          {application_data.map((app: any, index: any) => (
            <tr key={app._id || index} className="border-t">
              {app.insta_username === undefined ? (
                <td
                  className="border px-2 py-2"
                  onClick={() => {
                    setSelectedUser(app)
                    setIsOpenDetailModal(true)
                  }}
                >
                  <div className="flex flex-col items-center cursor-pointer whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <img
                        src={app.tiktok_profile_pic}
                        alt={app._id}
                        className="w-12 h-12 rounded-xl"
                      />
                      {app.verification && <CheckBadgeIcon className="w-6 h-6 text-blue-500" />}
                    </div>
                    <div>@{app.tiktok_username}</div>
                  </div>
                </td>
              ) : (
                <td
                  className="border px-2 py-2"
                  onClick={() => {
                    setSelectedUser(app)
                    setIsOpenDetailModal(true)
                  }}
                >
                  <div className="flex flex-col items-center cursor-pointer whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <img
                        src={app.insta_profile_pic}
                        alt={app._id}
                        className="w-12 h-12 rounded-xl"
                      />
                      {app.verification && <CheckBadgeIcon className="w-6 h-6 text-blue-500" />}
                    </div>
                    <div>@{app.insta_username}</div>
                  </div>
                </td>
              )}
              <td className="border px-2 py-2">
                <div className="flex items-center justify-between">
                  <button
                    className="text-indigo-600 bg-indigo-100 rounded px-2 py-1 ml-3 text-xs"
                    onClick={() => handleOpenUpdateModal(app)}
                  >
                    Düzenle
                  </button>
                </div>
              </td>
              <td className="border px-2 py-2">
                <div className="flex items-center justify-between">
                  {app.user}{" "}
                  <button
                    className="text-indigo-600 bg-indigo-100 rounded px-2 py-1 ml-3 text-xs"
                    onClick={() => setAssignUserModal(true)}
                  >
                    Düzenle
                  </button>
                </div>
              </td>
              {app.insta_followers === undefined ? (
                <td className="border px-2 py-2">{app.tiktok_followers}</td>
              ) : (
                <td className="border px-2 py-2">{app.insta_followers}</td>
              )}
              <td className="border px-2 py-2">
                {app.city?.charAt(0).toUpperCase() + app.city?.slice(1)}
              </td>
              <td className="border px-2 py-2">{app.age > 65 ? "65" : app.age}</td>
              <td className="border px-2 py-2">
                <div className="text-center items-center">
                  {app.gender === "male" ? (
                    <FontAwesomeIcon icon={faMars} style={{ color: "#005eff" }} />
                  ) : app.gender === "female" ? (
                    <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
                  ) : (
                    <div>diğer</div>
                  )}
                </div>
              </td>
              <td className="border px-2 py-2">{`${numFixer(app.insta_engagement_rate)}%`}</td>
              <td className="border px-2 py-2">
                <div className="flex flex-col items-center justify-center w-32 whitespace-nowrap">
                  <p>
                    {app.price_user} / {app.price_brand} {app.currency}
                  </p>
                  <button
                    className="text-indigo-600 bg-indigo-100 rounded px-2 py-1 ml-6 mt-1 text-xs"
                    onClick={() => handleOpenPricingModal(app)}
                  >
                    Düzenle
                  </button>
                </div>
              </td>
              {selectedState === "waiting_content" && (
                <>
                  <td className="border px-2 py-2">
                    <div className="flex flex-col items-center justify-center w-32 whitespace-nowrap">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-indigo-600"
                        checked={app.is_called}
                        onChange={() => {
                          handleIsCalled(app._id, !app.is_called, clearAdmin)
                        }}
                      />
                    </div>
                  </td>
                  <td className="border px-2 py-2">
                    <div>
                      <p>{app.info_state}</p>
                      <button
                        className="text-indigo-600 bg-indigo-100 rounded px-2 py-1 ml-6 mt-1 text-xs"
                        onClick={() => handleOpenInfoModal(app)}
                      >
                        Düzenle
                      </button>
                    </div>
                  </td>
                </>
              )}
              {["waiting_content", "invite_new", "waiting_address"].includes(selectedState) && (
                <>
                  <td className="border px-2 py-2">
                    <div className="flex items-center justify-between">
                      <VoiceMessagesList record={app} />
                    </div>
                  </td>
                </>
              )}
              <AssingUser
                modalOpen={assignUserModal}
                onClose={() => setAssignUserModal(false)}
                app={app}
                handleAssignUser={handleAssignUser}
                user_id={user_id}
                setUserId={setUserId}
                setAssignUserModal={setAssignUserModal}
              />
            </tr>
          ))}
        </tbody>
      </table>

      <EditUserPriceBrand
        modalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        newPricingUser={newPricingUser}
        setNewPricingUser={setNewPricingUser}
        newPricingBrand={newPricingBrand}
        setNewPricingBrand={setNewPricingBrand}
        handleSavePricingData={handleSavePricingData}
        handleClosePricingModal={handleClosePricingModal}
        application={application}
      />
      <EditApplicationNote
        modalOpen={stateModalOpen}
        onClose={() => setStateModalOpen(false)}
        infoState={infoState}
        setInfoState={setInfoState}
        handleSaveState={handleInfoState}
        handleCloseInfoModal={handleCloseInfoModal}
        application={application}
      />
      {isUpdateModalOpen && (
        <UpdateApplicationModal
          isOpen={isUpdateModalOpen}
          onClose={handleCloseUpdateModal}
          _id={selectedApp._id}
          campaign_id={selectedApp.campaign}
          state={selectedApp.state}
        />
      )}
    </div>
  )
}

export default DetailedApplicationsTable
